import errorHandler from '@/utils/errorHandler';
import { authorizedAxios } from '../../config/axios';

export default {
  async fetchProduct({ commit }, { companyId, productId }) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        `/api/v1/products/${productId}?company_id=${companyId}`
      );

      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchProducts({ commit }, params = {}) {
    try {
      commit('setLoading', true);
      const resp = await authorizedAxios.get('/api/v1/products', {
        params,
      });

      commit('setProducts', resp.data.data.products);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },

  async addProduct({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);
      const resp = await authorizedAxios.post('/api/v1/products', payload);

      commit('setShowSuccessfulActionModal', true);
      dispatch('fetchProducts');
      return resp;
    } catch (error) {
      errorHandler(error, 'editProduct');
    } finally {
      commit('setLoading', false);
    }
  },

  async updateProduct({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.put(
        `/api/v1/products/${payload.id}`,
        payload.data
      );

      dispatch('fetchProducts');
      commit('setShowSuccessfulActionModal', true);
      return resp;
    } catch (error) {
      errorHandler(error, 'editProduct');
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteProduct({ dispatch }, id) {
    try {
      const resp = await authorizedAxios.delete(`/api/v1/products/${id}`);
      dispatch('fetchProducts');
      return resp;
    } catch (error) {
      errorHandler(error, 'deleteProduct');
    }
  },

  async deleteProductImage({ commit }, payload) {
    try {
      commit('setLoading', true);

      const { data } = await authorizedAxios.post(
        `/api/v1/remove-product-image`,
        payload
      );

      commit('setShowSuccessfulActionModal', true);
      return data;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchCompanyWithProducts({ commit }, id) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        `/api/v1/company-profile-from-product-category/${id}`
      );

      commit('setCompanyWithProducts', resp.data.data.data);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },

  // Business categories
  async fetchProductCategories({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        '/api/v1/company-product-categories',
        { params }
      );

      commit('setProductCategories', resp.data.data.categories);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },

  // Categories that company creates
  async fetchCustomProductCategories({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(`/api/v1/product-categories`, {
        params,
      });

      commit('setCustomProductCategories', resp.data.data.productCategories);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchProductCompanies({ commit }, category, subCategory = null) {
    try {
      const resp = await authorizedAxios.get(
        `/api/v1/companies-per-business-product-category/${category}`,
        {
          params: {
            subcategory_id: subCategory,
          },
        }
      );

      commit('setProductCompanies', resp.data.data.companies);
      return resp;
    } catch (error) {
      errorHandler(error);
    }
  },
};
