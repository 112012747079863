import Vue from 'vue';

Vue.filter('EUR', number => {
  if (!number) {
    return '';
  }

  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);
});

Vue.filter('resolveImagesJson', (val, placeholder = null) => {
  if (!val) {
    if (placeholder) {
      return 'https://via.placeholder.com/' + placeholder;
    }

    return '';
  }

  try {
    return JSON.parse(val)[0];
  } catch (err) {
    return val;
  }
});
