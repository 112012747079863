import store from '../index';

export default {
  appointments: state => state.appointments,
  appointmentsLoaded: state => state.appointmentsLoaded,
  appointmentsToTable: state =>
    state.appointments.map(item => ({
      service: { name: item.serviceData.name, id: item.id },
      date: item.dateStart,
      time: `${item.timeStart} - ${item.timeEnd}`,
      staff: `${item.staffUser.firstName} ${item.staffUser.lastName}`,
      client: `${item.clients.firstName} ${item.clients.lastName}`,
      cost: item.serviceData.price,
    })),
  appointmentsToTableSimple: state =>
    state.appointments
      ? state.appointments.map(i => ({
          date: i.dateStart,
          time: {
            start: i.timeStart,
            end: i.timeEnd,
          },
          quantity: i.limit,
        }))
      : [],
  appointmentsToCalendarEvents: (state, getters) =>
    state.appointments.map(item => ({
      start: `${item.dateStart} ${item.timeStart}`,
      end: `${item.dateEnd} ${item.timeEnd}`,
      title: getters.isAdminServiceProvider
        ? item.serviceData?.name
        : item.companyUser?.companyName,
      appointmentId: item.id,
      appointment: item,
      class: item.confirmed ? 'active' : 'canceled',
    })),
  appointmentById: state => state.appointmentById,
  appointmentDetailsAdminServiceProvider: (_, getters) => {
    return {
      service: {
        name: getters.appointmentById.serviceData?.name,
        id: getters.appointmentById.id,
      },
      date: getters.appointmentById.dateStart,
      time: `${getters.appointmentById.timeStart?.slice(
        0,
        5
      )} - ${getters.appointmentById.timeEnd?.slice(0, 5)}`,
      note: getters.appointmentById.note,
      staff: `${getters.appointmentById.staffUser?.firstName} ${getters.appointmentById.staffUser?.lastName}`,
      client: getters.appointmentById.clients?.firstName
        ? `${getters.appointmentById.clients?.firstName} ${getters.appointmentById.clients?.lastName}`
        : getters.appointmentById.clientId,
      cost: getters.appointmentById.serviceData?.price,
    };
  },
  appointmentDetailsAdminRetailer: (_, getters) => {
    return {
      retailer: {
        name: getters.appointmentById.companyUser?.companyName,
        id: getters.appointmentById.id,
      },
      // client:
      //   getters.appointmentById.clients?.length > 0 &&
      //   getters.appointmentById.clients[0].firstName
      //     ? `${getters.appointmentById.clients[0].firstName} ${getters.appointmentById.clients[0].lastName}`
      //     : getters.appointmentById.clients?.length > 0 &&
      //       getters.appointmentById.clients[0].name
      //     ? getters.appointmentById.clients[0].name
      //     : '-',
      clients: getters.appointmentById.clients,
      date: getters.appointmentById.dateStart,
      time: `${getters.appointmentById.timeStart?.slice(
        0,
        5
      )} - ${getters.appointmentById.timeEnd?.slice(0, 5)}`,
      note: getters.appointmentById.note,
      limit: getters.appointmentById.limit,
    };
  },
  appointmentDetailsEndUser: (_, getters) => {
    const companyType = getters.appointmentById.companyUser?.type;
    console.log(
      'getters.appointmentById.companyUser?.companyName :>> ',
      getters.appointmentById.companyUser?.companyName
    );
    return {
      companyName: getters.appointmentById.companyUser?.companyName,
      address: getters.appointmentById.companyUser?.address,
      booking:
        companyType === 'service_provider'
          ? {
              name: getters.appointmentById.serviceData?.name,
              id: getters.appointmentById.id,
            }
          : false,
      // : {
      //     name: getters.appointmentById.companyUser?.companyName,
      //     id: getters.appointmentById.id,
      //   },
      date: getters.appointmentById.dateStart,
      time: `${getters.appointmentById.timeStart?.slice(
        0,
        5
      )} - ${getters.appointmentById.timeEnd?.slice(0, 5)}`,
      staff:
        companyType === 'service_provider'
          ? `${getters.appointmentById.staffUser?.firstName} ${getters.appointmentById.staffUser?.lastName}`
          : false,
      cost:
        companyType === 'service_provider'
          ? getters.appointmentById.serviceData?.price
          : false,
      note: getters.appointmentById.note || false,
    };
  },
  appointmentByIdDetails: (_, getters) => {
    const role = getters.userInfo.role;
    const companyType = getters.userInfo.type;
    if (role === 'admin' && companyType === 'service_provider') {
      return getters.appointmentDetailsAdminServiceProvider;
    } else if (role === 'admin' && companyType === 'retailer') {
      return getters.appointmentDetailsAdminRetailer;
    } else if (role === 'user') {
      return getters.appointmentDetailsEndUser;
    }
  },
  appointmentByIdDetailsOptions: (state, getters) => {
    return {
      id: getters.appointmentById.id,
      confirmed: getters.appointmentById.confirmed,
    };
  },
  periodOptions: state => state.periodOptions,
  periodOptionsEnduser: state => state.periodOptionsEnduser,
  selectedStaff: state => state.selectedStaff,
  selectedLocation: state => state.selectedLocation,
  enduserSortOptions: state => state.enduserSortOptions,
};
