<template>
  <label :for="id" class="checkbox-container">
    <input
      :id="id"
      class="checkbox"
      type="checkbox"
      :value="value"
      @change="handleChange"
      :disabled="readOnly"
      ref="checkboxInput"
    />
    <div
      class="checkbox-custom empty"
      :class="{
        orange: isChecked && !readOnly,
        grey: (isChecked && readOnly) || (isChecked && color === 'grey'),
        disabled: readOnly,
      }"
    >
      <CheckboxIcon v-if="isChecked" />
    </div>
  </label>
</template>

<script>
import CheckboxIcon from '../Svgs/CheckboxIcon.vue';

export default {
  components: {
    CheckboxIcon,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    id() {
      return `id_${this.value}_${Math.random() * 100}`;
    },
    isChecked() {
      // return this.modelValue.includes(String(this.value));
      return this.modelValue.find(el => String(el) == String(this.value));
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.$nextTick(() => {
          const checkboxInput = this.$refs.checkboxInput;
          if (!checkboxInput) {
            return;
          }
          if (newVal?.includes(this.value)) {
            checkboxInput.checked = true;
            this.checked = true;
          } else if (!newVal?.includes(this.value)) {
            checkboxInput.checked = false;
            this.checked = false;
          }
        });
      },
    },
  },
  methods: {
    handleChange(event) {
      this.checked = this.isChecked;
      const newVal = [...this.modelValue];

      if (this.checked) {
        newVal.splice(newVal.indexOf(this.value), 1);
      } else {
        newVal.push(this.value);
      }
      this.$emit('input', newVal);
      this.$emit('checked', this.checked);
      this.$emit('latestChecked', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  position: relative;
}
.checkbox {
  position: absolute;
  opacity: 0;
}
.checkbox-custom {
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: $hty-border;
  border-radius: 4px;
  background-color: white;

  > svg {
    position: absolute;
    left: 3px;
    top: 5px;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $hty-grey-border;
    border-color: $hty-grey-border !important;
    pointer-events: none;
  }

  &.orange,
  &.empty {
    border-color: $hty-orange;
  }

  &.orange {
    background-color: $hty-orange;
  }

  &.grey {
    background-color: $hty-grey;
  }
}
</style>
