export default {
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setAdminRegistrationData: (state, { key, value }) => {
    state.adminRegistrationData[key] = value;
  },
  setAdminCompanyType: (state, payload) => {
    state.adminRegistrationData.type = payload;
  },
  setAdminFirstName: (state, payload) => {
    state.adminRegistrationData.firstName = payload;
  },
  setAdminLastName: (state, payload) => {
    state.adminRegistrationData.lastName = payload;
  },
  setAdminCompanyName: (state, payload) => {
    state.adminRegistrationData.companyName = payload;
  },
  setAdminEmail: (state, payload) => {
    state.adminRegistrationData.email = payload;
  },
  setAdminPassword: (state, payload) => {
    state.adminRegistrationData.password = payload;
  },
  setAdminConfirmPassword: (state, payload) => {
    state.adminRegistrationData.confirmPassword = payload;
  },
  setAdminPhoneNumber: (state, payload) => {
    state.adminRegistrationData.phoneNumber = payload;
  },
  setAdminMobileNumber: (state, payload) => {
    state.adminRegistrationData.mobileNumber = payload;
  },
  setAdminCountry: (state, payload) => {
    state.adminRegistrationData.country = payload;
  },
  setAdminCity: (state, payload) => {
    state.adminRegistrationData.city = payload;
  },
  setAdminAddress: (state, payload) => {
    state.adminRegistrationData.address = payload;
  },
  setAdminZipcode: (state, payload) => {
    state.adminRegistrationData.zipcode = payload;
  },
  setAdminCategory: (state, payload) => {
    state.adminRegistrationData.category = payload.join(',');
  },
  setAdminSubcategory: (state, payload) => {
    state.adminRegistrationData.subcategory = payload.join(',');
  },
  setAdminTaxId: (state, payload) => {
    state.adminRegistrationData.taxId = payload;
  },
  setAdminLegalEntity: (state, payload) => {
    state.adminRegistrationData.companyType = payload;
  },
  setUserVerifyPhoneCountry: (state, payload) => {
    state.userVerifyPhone.callingCode = payload;
  },
  setUserVerifyPhoneNumber: (state, payload) => {
    state.userVerifyPhone.phoneNumber = payload;
  },
  setCompanyStaff: (state, payload) => {
    state.companyStaff = payload;
  },
  setCompanyAppointments: (state, payload) => {
    state.companyAppointments = payload;
  },
  setStaffUsers: (state, payload) => {
    state.staffUsers = payload;
  },
  clearAdminRegistrationData: state => {
    state.adminRegistrationData = {
      type: '',
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      mobileNumber: '',
      country: '',
      city: '',
      address: '',
      zipcode: '',
      category: '',
      subcategory: '',
      taxId: '',
      companyType: null,
      personalausweis: null,
      gewerbeanmeldung: null,
      angaben_der_wirtschaftlich_berechtigten: null,
      gesellschaftervertrag: null,
      personalausweis_des_geschäftsführers_und_aller_wirtschaftlich_berechtigten_personen: null,
      aktueller_firmenbuchauszug_handelsregisterauszug: null,
      handwerkskarte: null,
    };
  },
};
