<template>
  <div class="avatar-container">
    <div
      @click="isOpen = blockOpen ? isOpen : !isOpen"
      role="button"
      tabindex="0"
      class="avatar-icon"
    >
      <div class="avatar-img-container">
        <img
          v-if="$store.getters.userInfo.avatar"
          :src="$store.getters.userInfo.avatar"
        />
        <img v-else :src="require('@/assets/pics/default_avatar.png')" />
        <!-- <SlackIcon v-else /> -->
      </div>
    </div>
    <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
      <DropdownElement v-if="isOpen" top="60px" left="-250px" width="300px">
        <div class="dropdown">
          <div class="header">
            <div class="title">
              {{ $store.getters.userInfo.companyName }}
            </div>
            <div class="subtitle">
              {{ $store.getters.userInfo.firstName }}
              {{ $store.getters.userInfo.lastName }}
            </div>
          </div>
          <div class="list">
            <div class="list-item" v-if="$store.getters.isEndUser">
              <UserIcon />
              <router-link :to="{ name: 'SettingsProfile' }" class="text">
                <span @click="isOpen = false">
                  {{ $t('retailer.dashboard.myAccount') }}
                </span>
              </router-link>
            </div>
            <div class="list-item" v-if="!$store.getters.isEndUser">
              <SettingsIcon color="#E58413" />
              <router-link :to="{ name: 'SettingsCompany' }" class="text">
                <span @click="isOpen = false">
                  {{ $t('retailer.dashboard.companySettings') }}
                </span>
              </router-link>
            </div>
            <div
              @click="$emit('logout')"
              role="button"
              tabindex="0"
              class="list-item"
            >
              <LogoutIcon />
              <div class="text">
                {{ $t('retailer.dashboard.signOut') }}
              </div>
            </div>
          </div>
        </div>
      </DropdownElement>
    </OutsideClickContainer>
  </div>
</template>

<script>
import DropdownElement from '../Dropdown/DropdownElement.vue';
import UserIcon from '../Svgs/UserIcon.vue';
import SettingsIcon from '../Svgs/Sidebar/SettingsIcon.vue';
import LogoutIcon from '../Svgs/LogoutIcon.vue';
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer.vue';

export default {
  data() {
    return {
      blockOpen: false,
      isOpen: false,
    };
  },
  watch: {
    isOpen(val) {
      if (!val) {
        this.blockOpen = true;
        setTimeout(() => {
          this.blockOpen = false;
        }, 100);
      }
    },
  },
  components: {
    OutsideClickContainer,
    UserIcon,
    DropdownElement,
    LogoutIcon,
    SettingsIcon,
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  position: relative;
}
.avatar-img-container {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.avatar-icon {
  cursor: pointer;
}
.list-item {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
  cursor: pointer;

  &:last-child {
    padding-bottom: 0px;
  }

  .text {
    font-weight: 500;
    padding-top: 3px;
    margin-left: 0.7rem;
    color: inherit !important;
  }
}
.dropdown {
  padding: 1.5rem;
  box-shadow: $hty-shadow-strong;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2;
  color: $hty-orange;
  margin-bottom: 0.6rem;
}
.subtitle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-black;
  margin-bottom: 0.6rem;
}
</style>
