import { serviceBookAppointmentPayload } from './state';

export default {
  setServiceType: (state, payload) => {
    state.serviceType = payload;
  },
  setServiceCategories: (state, payload) => {
    state.serviceCategories = payload;
  },
  setServiceCompanies: (state, payload) => {
    state.serviceCompanies = payload;
  },
  setServiceCompanyDetails: (state, payload) => {
    state.serviceCompanyDetails = payload;
  },
  setServiceBookAppointmentPayload: (state, { key, value } = {}) => {
    state.serviceBookAppointmentPayload[key] = value;
  },
  setServiceSelectedService: (state, payload) => {
    state.serviceSelectedService = payload;
  },
  servicesClearBooking: state => {
    state.serviceCompanyDetails = {};
    state.serviceSelectedService = {};
    state.serviceBookAppointmentPayload = serviceBookAppointmentPayload();
  },
};
