export default {
  SET_CART(state, value) {
    state.cart = value;

    // Fill state.cartItems
    if (state.cart?.items) {
      let items = [];

      for (const index in state.cart.items) {
        items.push(state.cart.items[index]);
      }

      state.cartItems = items;
    } else {
      state.cartItems = [];
    }
  },
};
