import {
  contactForm,
  getCountries,
  globalSearch,
  searchClients,
} from '../../../../services/general';
import errorHandler from '../../../../utils/errorHandler';

export default {
  async fetchCountries({ commit }) {
    try {
      commit('setLoading', true);
      const { data } = await getCountries();
      commit('setCountries', data.data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async searchClients({ commit }, searchParam) {
    try {
      commit('setLoading', true);
      const { data } = await searchClients(searchParam);
      commit('setClients', Array.isArray(data.data.data) ? data.data.data : []);
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async contactForm({ commit }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await contactForm(payload);
      commit('setToastAlert', {
        text: data.message,
      });
    } catch (error) {
      errorHandler(error, 'contactForm');
    } finally {
      commit('setLoading', false);
    }
  },
  async globalSearch({ commit }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await globalSearch(payload);
      commit('setGlobalSearch', data.data.data);
    } catch (error) {
      commit('setGlobalSearch', []);
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
