<template>
  <div class="header-container">
    <div class="menu-button d-lg-none mr-3">
      <ToggleButton />
    </div>
    <div class="search" v-if="$store.getters.isEndUser">
      <GlobalSearch />
    </div>
    <div class="right ml-auto">
      <div
        class="shopping-cart-wrapper"
        v-if="$store.getters.isEndUser"
        @click="goToCart()"
      >
        <span
          v-if="$store.getters.cartItems.length"
          class="shopping-cart-amount"
        >
          {{ Object.keys($store.getters.cartItems).length }}
        </span>
        <ProductsIcon width="20px" height="20px" color="#E58413" />
      </div>
      <div class="notification">
        <NotificationAlarm :unread="$store.getters.unreadNotifications" />
      </div>
      <div class="avatar">
        <AccountDropdown @logout="handleLogout" />
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../router';
import store from '../../store';
import AccountDropdown from '../AccountDropdown/AccountDropdown.vue';
import NotificationAlarm from '../NotificationAlarm/NotificationAlarm.vue';
import GlobalSearch from '../Search/GlobalSearch.vue';
import ToggleButton from '@/components/Sidebar/ToggleButton';
import ProductsIcon from '@/components/Svgs/Sidebar/ProductsIcon';

export default {
  setup() {
    const handleLogout = async () => {
      await store.dispatch('logoutUser');
      router.push({ name: 'Home' });
    };

    return {
      handleLogout,
    };
  },
  components: {
    ProductsIcon,
    GlobalSearch,
    AccountDropdown,
    NotificationAlarm,
    ToggleButton,
  },
  methods: {
    goToCart() {
      if (this.$route.name !== 'Cart') {
        this.$router.push({ name: 'Cart' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 100px;
}
.search {
  // width: 50%;
  width: 400px;
}
.right {
  display: flex;
  > :last-child {
    margin-left: 1rem;
  }
  .shopping-cart-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 14px;
    position: relative;
    cursor: pointer;

    .shopping-cart-amount {
      align-items: center;
      border-radius: 50%;
      color: white;
      background-color: #e58413;
      display: flex;
      font-size: 12px;
      width: 18px;
      height: 18px;
      justify-content: center;
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }
}
</style>
