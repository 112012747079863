export default {
  loading: state => state.loading,
  isLoggedIn: state => state.isLoggedIn,
  notFound: state => state.notFound,
  offlineBanner: state => state.offlineBanner,
  countries: state => state.countries,
  clients: state => state.clients,
  globalSearch: state => state.globalSearch,
  countriesOptions: state =>
    state.countries.map(item => ({
      label: item.name,
      value: item.cc,
    })),
  countriesVerifyPhoneOptions: state =>
    state.countries.map(item => ({
      label: item.cc,
      value: item.callingCode,
      extended: item,
    })),
};
