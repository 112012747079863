<template>
  <div>
    <LoginLayout v-if="loginLayout($route)" />
    <MainLayout v-else-if="mainLayout($route)" />
    <NotFoundLayout v-else />
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api';
import LoginLayout from './layouts/LoginLayout.vue';
import MainLayout from './layouts/MainLayout.vue';
import store from './store';
import router from './router';
import NotFoundLayout from './layouts/NotFoundLayout.vue';

export default {
  setup() {
    const loginLayout = route => {
      // special handling for UserVerifyAccount here since its actually logged in(Main layout),
      // but we need it to render at login layout
      return (
        route.name === 'UserVerifyAccount' ||
        (!store.getters.isLoggedIn && !store.getters.notFound)
      );
    };
    const mainLayout = route => {
      return (
        route.name !== 'UserVerifyAccount' &&
        store.getters.isLoggedIn &&
        !store.getters.notFound
      );
    };

    return {
      loginLayout,
      mainLayout,
    };
  },
  components: {
    NotFoundLayout,
    MainLayout,
    LoginLayout,
  },
};
</script>

<style lang="scss">
@import './assets/styles/fonts.scss';
</style>
