import { ref, watch, watchEffect } from '@vue/composition-api';

export default function useMultiSelect(isOpen, emit) {
  const checkboxValues = ref([]);
  watch(checkboxValues, newCheckboxValues => {
    console.log('newCheckboxValues :>> ', newCheckboxValues);
    emit('input', newCheckboxValues);
  });

  watchEffect(() => {
    // console.log('checkboxValues.value :>> ', checkboxValues.value);
  });

  return [checkboxValues];
}
