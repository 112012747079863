<template>
  <RouterLink
    v-if="anchor"
    :to="anchor"
    class="anchor button"
    :class="{
      grey,
      orange,
      'orange-secondary': orangeSecondary,
      'grey-secondary': greySecondary,
      link,
      disabled,
      block,
    }"
    :style="style"
    :event="!disabled ? 'click' : ''"
  >
    <slot>{{ text }}</slot>
  </RouterLink>
  <button
    v-else
    class="button"
    :type="type"
    :class="{
      grey,
      orange,
      'orange-secondary': orangeSecondary,
      'grey-secondary': greySecondary,
      link,
      disabled,
      block,
    }"
    :style="style"
    :disabled="disabled || loading"
    @click="$emit('click')"
  >
    <template v-if="!loading">
      <slot>{{ text }}</slot>
    </template>
    <template v-else>
      <img width="50" :src="require('@/assets/svg/spinner_white.svg')" />
    </template>
  </button>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  setup(props) {
    const style = computed(() => {
      if (!props.color) return null;
      return {
        'background-color': props.color,
        border: `1px solid ${props.color}`,
      };
    });

    return {
      style,
    };
  },
  props: {
    anchor: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    greySecondary: {
      type: Boolean,
      default: false,
    },
    orange: {
      type: Boolean,
      default: false,
    },
    orangeSecondary: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor {
  text-decoration: none;
}
.button {
  cursor: pointer !important;
  padding: 10px 16px;
  border-radius: $hty-radius;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.orange {
    background-color: $hty-orange;
    color: white;
    border: 1px solid $hty-orange;
    height: 44px;
  }

  &.orange-secondary {
    background-color: transparent;
    color: $hty-orange;
    border: 1px solid $hty-orange;
    height: 44px;
  }

  &.grey {
    background-color: $hty-grey;
    color: #2b2b2b;
    border: 1px solid $hty-grey;
    height: 44px;
  }

  &.grey-secondary {
    background-color: transparent;
    color: $hty-dark-grey;
    border: 1px solid #d4d4d4;
    height: 44px;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.link {
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    color: $hty-orange;

    &.disabled {
      padding: 0;
      border: none;
      border-radius: 0;
      outline: none;
      background-color: transparent;
      color: $hty-orange;
    }

    &.grey {
      color: $hty-dark-grey;
    }
  }

  &.block {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
</style>
