<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M9 22.9976C9.55228 22.9976 10 22.5498 10 21.9976C10 21.4453 9.55228 20.9976 9 20.9976C8.44772 20.9976 8 21.4453 8 21.9976C8 22.5498 8.44772 22.9976 9 22.9976Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 22.9976C20.5523 22.9976 21 22.5498 21 21.9976C21 21.4453 20.5523 20.9976 20 20.9976C19.4477 20.9976 19 21.4453 19 21.9976C19 22.5498 19.4477 22.9976 20 22.9976Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 1.99756H5L7.68 15.3876C7.77144 15.848 8.02191 16.2615 8.38755 16.5559C8.75318 16.8502 9.2107 17.0066 9.68 16.9976H19.4C19.8693 17.0066 20.3268 16.8502 20.6925 16.5559C21.0581 16.2615 21.3086 15.848 21.4 15.3876L23 6.99756H6"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          :fill="color"
          transform="translate(0 0.997559)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="scss" scoped></style>
