import { ref, computed, watchEffect } from '@vue/composition-api';

export default function useFilterData(
  data,
  { getFrom, filteringProperties } = {}
) {
  const searchInput = ref('');

  const filtered = computed(() =>
    getFrom && filteringProperties
      ? data.value.filter(item =>
          filteringProperties.some(prop =>
            item[getFrom][prop]
              .toLowerCase()
              .includes(searchInput.value.toLowerCase())
          )
        )
      : data.value.filter(item =>
          item.label?.toLowerCase().includes(searchInput.value.toLowerCase())
        )
  );

  return [filtered, searchInput];
}
