import storage from '../../../../config/localStorage';
import errorHandler from '../../../../utils/errorHandler';
import { authorizedAxios, mainAxios } from '../../../../config/axios';

export default {
  async registerCompanyUser({ commit }, data) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);

      let formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      const resp = await mainAxios.post('/api/v1/company/register', formData);

      return resp;
    } catch (error) {
      errorHandler(error, 'registerCompanyUser');
    } finally {
      commit('setLoading', false);
    }
  },
  async loginCompanyUser({ commit }, { validator, ...payload }) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);

      const resp = await mainAxios.post('/api/v1/company/login', payload);

      storage.set('access_token', resp.data.data.authToken);
      commit('setLoggedIn', true);
      return resp;
    } catch (error) {
      if (error?.response?.data.message) {
        validator.setErrors({
          email: [error.response.data.message],
          password: [error.response.data.message],
        });
      }
      errorHandler(error, 'login');
    } finally {
      commit('setLoading', false);
    }
  },
  async uploadFilesCompanyUser({ commit, dispatch }, data) {
    try {
      commit('setLoading', true);

      let formData = new FormData();
      Object.keys(data).forEach(key => {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      });

      const resp = await authorizedAxios.post(
        '/api/v1/company/upload-file',
        formData
      );

      dispatch('fetchUserInfo');
      commit('setShowSuccessfulActionModal', true);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async forgotPasswordCompanyUser({ commit }, payload) {
    try {
      commit('setLoading', true);

      const resp = await mainAxios.post(
        '/api/v1/company/forgot-password',
        payload
      );

      commit('setToastAlert', { text: resp.data.message });
      return resp;
    } catch (error) {
      errorHandler(error, 'forgotPassword');
    } finally {
      commit('setLoading', false);
    }
  },
  async resetPasswordCompanyUser({ commit }, payload) {
    try {
      commit('setLoading', true);

      const resp = await mainAxios.post(
        '/api/v1/company/reset-password',
        payload
      );

      return resp;
    } catch (error) {
      errorHandler(error, 'resetPassword');
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCompanyStaff({ commit }, payload) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        `/api/v1/company/${payload.companyId}/staff`,
        { params: { serviceId: payload.serviceId } }
      );

      commit('setCompanyStaff', resp.data.data.staff);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCompanyAppointments({ commit }, { companyId, serviceId } = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        `/api/v1/company/${companyId}/appointments`,
        { params: { serviceId } }
      );

      commit('setCompanyAppointments', resp.data.data.appointments);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
