import { dateFormat, subtractTime } from '../../utils/dateTimeHelpers';

export default {
  appointments: [],
  appointmentsLoaded: false,
  appointmentById: {},
  selectedStaff: [],
  selectedLocation: [],
  periodOptions: [
    {
      label: 'Tag',
      value: 'day',
    },
    {
      label: 'Woche',
      value: 'week',
    },
    {
      label: 'Monat',
      value: 'month',
    },
    {
      label: 'Jahr',
      value: 'year',
    },
  ],
  periodOptionsEnduser: [
    {
      label: 'Heute',
      value: { param: 'day', query: dateFormat(Date.now(), 'y-MM-dd') },
    },
    {
      label: 'Gestern',
      value: {
        param: 'day',
        query: dateFormat(subtractTime(Date.now(), { days: 1 }), 'y-MM-dd'),
      },
    },
    {
      label: 'In dieser Woche',
      value: { param: 'week', query: dateFormat(Date.now(), 'y-MM-dd') },
    },
    {
      label: 'Letzte Woche',
      value: {
        param: 'week',
        query: dateFormat(subtractTime(Date.now(), { weeks: 1 }), 'y-MM-dd'),
      },
    },
    {
      label: 'Diesen Monat',
      value: { param: 'month', query: dateFormat(Date.now(), 'y-MM-dd') },
    },
    {
      label: 'Letzten Monat',
      value: {
        param: 'month',
        query: dateFormat(subtractTime(Date.now(), { months: 1 }), 'y-MM-dd'),
      },
    },
    {
      label: 'Dieses Jahr',
      value: { param: 'year', query: dateFormat(Date.now(), 'y-MM-dd') },
    },
    {
      label: 'Letztes Jahr',
      value: {
        param: 'year',
        query: dateFormat(subtractTime(Date.now(), { years: 1 }), 'y-MM-dd'),
      },
    },
  ],
  enduserSortOptions: [
    { label: 'Uhrzeit', value: 'time' },
    { label: 'Datum', value: 'date' },
    { label: 'Dienstleistung', value: 'service' },
    // { label: 'enduser.calendar.staff', value: 'staff' },
    // { label: 'enduser.calendar.cost', value: 'price' },
  ],
};
