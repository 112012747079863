import { dateFormat } from '../utils/dateTimeHelpers';

export default {
  Login: { role: 'user' },
  Calendar: {
    selectedDate: dateFormat(Date.now(), 'y-MM-dd'),
    sortBy: 'date',
    sort: 'asc',
  },
};
