import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { resourceUrl } from '../vars';
import { redirectionInterceptorResponseError } from './interceptors/redirection';
import store from '../../store/index';
import {
  authorizationInterceptorRequestSuccess,
  authorizationInterceptorResponseError,
} from './interceptors/authorization';

export const mainAxios = applyCaseMiddleware(
  axios.create({
    baseURL: resourceUrl,
  })
);

export const authorizedAxios = applyCaseMiddleware(
  axios.create({
    baseURL: resourceUrl,
  })
);

// mainAxios interceptors
mainAxios.interceptors.request.use(
  req => {
    store.commit('setLoading', true);
    store.commit('setErrorResponse', {});
    return req;
  },
  err => Promise.reject(err)
);
mainAxios.interceptors.response.use(
  res => {
    store.commit('setLoading', false);
    return res;
  },
  err => {
    store.commit('setLoading', false);
    return Promise.reject(err);
  }
);

// authorizedAxios interceptors
authorizedAxios.interceptors.request.use(
  req => {
    store.commit('setLoading', true);
    store.commit('setErrorResponse', {});
    authorizationInterceptorRequestSuccess(req);

    return req;
  },
  err => Promise.reject(err)
);
authorizedAxios.interceptors.response.use(
  res => {
    store.commit('setLoading', false);

    // Lazy way to update notifications
    if (res.config.method != 'get' && res.config.url != '/api/v1/logout') {
      store.dispatch('fetchNotifications');
    }

    return res;
  },
  err => {
    store.commit('setLoading', false);
    authorizationInterceptorResponseError(err);
    redirectionInterceptorResponseError(err);

    return Promise.reject(err);
  }
);
