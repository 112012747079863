<template>
  <div ref="outsideClick" class="d-flex" style="max-width: 1125px">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleOutsideClick(event) {
      if (this.active) {
        const node = this.$refs.outsideClick;
        if (node && !node.contains(event.target)) {
          this.$emit('outsideClick');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
