import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
} from 'vee-validate';
import { required, email, min, oneOf, numeric } from 'vee-validate/dist/rules';
import { mainAxios } from './axios';

configure({
  mode: 'eager',
});

extend('email', {
  ...email,
  message: 'E-Mail Adresse konnte nicht gefunden werden.', //there is vee-validate/i18n , so that should be implemented in the future prolly
  // as it is hardcoded now
});

extend('phone', {
  validate(value) {
    const phoneRegex = /^\+[1-9]\d{1,14}$/i;
    return phoneRegex.test(value);
  },
  message: 'Telefonnummer ist ungültig',
});

extend('required', {
  ...required,
  message: 'Dieses Feld wird benötigt',
});

extend('numeric', {
  ...numeric,
  message: 'Nur numerische Zeichen erlaubt',
});

extend('requiredCustom', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined, 0, false].indexOf(value) === -1,
    };
  },
  computesRequired: true,
  message: 'Dieses Feld wird benötigt',
});

extend('min', {
  ...min,
  message: (fieldName, args) => `Mindestens ${args.length} Zeichen erlaubt`,
});

extend('oneOf', {
  ...oneOf,
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    console.log('value, target :>> ', value, target);
    return value === target;
  },
  message: 'Passwort stimmt nicht überein',
});
extend('checkIsUsed', {
  async validate(value) {
    const url = `/api/v1/check-email?email=${value}&user_role=company`;
    let status;
    try {
      await mainAxios
        .get(url)
        .then(res => {
          status = !res.data.success;
        })
        .catch(e => {
          status = !e.response.data.success;
        });
    } catch (e) {
      status = false;
    }
    return status;
  },
  message: 'Diese Email ist bereits belegt',
});

export { ValidationObserver, ValidationProvider };
