<template>
  <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
    <div class="select-container">
      <div
        class="select-input d-flex overflow-hidden"
        style="flex-wrap: wrap"
        :class="{ grey: backgroundColor === 'grey' }"
        @click="
          if (!disabled) {
            isOpen = !isOpen;
          }
        "
        role="button"
        tabindex="0"
      >
        <div
          class="selection"
          v-if="placeholderWithSelectionLength && selectedArray.length"
        >
          {{ `(${selectedArray.length}) ${placeholder}` }}
        </div>
        <div v-else-if="selected" class="selection">
          <slot name="selected-label" :extended="selected.extended">
            {{ placeholderGenerated || selected.label }}
          </slot>
        </div>
        <div v-else class="selection-empty">
          {{ placeholderGenerated || placeholder }}
        </div>
        <span class="icon" v-if="!disabled">
          <CarrotDownWide />
        </span>
      </div>
      <div class="select-dropdown-container">
        <InstaSearch
          v-if="isOpen"
          class="search"
          :class="{ active: search }"
          :data="options"
          placeholder="Suchen"
          @filtered-data="handleFilteredData"
          :filter-options="filterOptions"
        />
        <div v-if="isOpen" class="select-dropdown">
          <div v-if="!multiSelect" class="options-list">
            <div
              v-for="item of filteredData"
              @click="selectSingleItem(item)"
              role="button"
              tabindex="0"
              :key="item[keyName]"
              class="options-item"
            >
              <slot name="list-label" :extended="item.extended">
                {{ item.label }}
              </slot>
            </div>
          </div>
          <div v-else class="options-list">
            <div
              v-for="item of filteredData"
              :key="item[keyName]"
              class="options-item multi"
            >
              <CheckboxMultiElement
                :value="item.value"
                :model-value="selectedArray"
                @input="selectedArray = $event"
              />
              <span>
                <slot :extended="item.extended">
                  {{ item.label }}
                </slot>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OutsideClickContainer>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api';
import CarrotDownWide from '../../Svgs/Carrots/CarrotDownWide.vue';
import CheckboxMultiElement from '../CheckboxMultiElement.vue';
import useSingleSelect from './composables/useSingleSelect';
import useMultiSelect from './composables/useMultiSelect';
import InstaSearch from '../../Search/InstaSearch.vue';
import OutsideClickContainer from '../../OutsideClickContainer/OutsideClickContainer.vue';
import { capitalize } from '../../../utils/helpers';

export default {
  setup(props, { emit }) {
    const isOpen = ref(false);
    const filteredData = ref(null);

    const [selected, selectSingleItem] = useSingleSelect(isOpen, emit);
    const [selectedArray] = useMultiSelect(isOpen, emit);

    const getOptionLabel = val => {
      const optionItem = props.options.find(
        i => i.value.toString() === val.toString()
      );
      return capitalize(optionItem ? optionItem.label : props.placeholder);
    };

    onMounted(() => {
      if (!Array.isArray(props.value)) {
        selected.value = {
          value: props.value.toString(),
          label: getOptionLabel(props.value),
        };
      } else {
        selectedArray.value = props.value;
      }
    });

    watch(isOpen, (newIsOpen, oldIsOpen) => {
      if (!newIsOpen && oldIsOpen) {
        emit('dropdown-closed', isOpen.value);
      }
    });

    watch(
      () => props.value,
      newVal => {
        if (!Array.isArray(newVal)) {
          if (newVal != selected.value.value) {
            selected.value = {
              value: newVal.toString(),
              label: getOptionLabel(newVal),
            };
          }
        } else {
          selectedArray.value = props.value;
        }
      }
    );

    const handleFilteredData = value => {
      filteredData.value = value;
    };

    return {
      isOpen,
      selectSingleItem,
      selected,
      selectedArray,
      handleFilteredData,
      filteredData,
    };
  },
  components: {
    InstaSearch,
    CheckboxMultiElement,
    CarrotDownWide,
    OutsideClickContainer,
  },
  computed: {
    selectedItems() {
      let items = [];
      if (this.selected && this.options.length) {
        console.log('selectedItem', this.selected);
        // for (let val of this.selected) {}
      }
      return items;
    },
    placeholderGenerated() {
      let str = '';

      for (let i = 0; i < this.selectedArray.length; ++i) {
        const item = this.options.find(o => o.value == this.selectedArray[i]);

        if (!item) {
          continue;
        }

        str += item.label;

        if (i != this.selectedArray.length - 1) {
          str += ', ';
        }
      }

      return str;
    },
  },
  props: {
    value: {
      type: [Number, String, Array],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
    keyName: {
      type: String,
      default: 'value',
    },
    placeholderWithSelectionLength: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  width: 100%;
}
.invalid .select-input {
  border-color: red !important;
}
.select-input {
  width: 100%;
  height: 44px;
  border: $hty-border;
  border-radius: $hty-radius;
  outline: none;
  padding-left: 0.9rem;
  padding-right: 2.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.grey {
    background-color: $hty-grey;
    border: 1px solid $hty-grey;
  }

  &.touched {
    border-color: $hty-red2;
  }
  &.active {
    border-color: $hty-red2;
  }
  &.passed {
    border-color: $hty-grey-border;
  }

  > .icon {
    position: absolute;
    right: 16px;
    top: 10px;
  }
}
.select-dropdown-container {
  width: 100%;
  border-radius: $hty-radius;
  background: $hty-white;
  box-shadow: $hty-shadow-dropdown;
  position: absolute;
  top: 50px;
  z-index: $z-index-dropdowns;
}
.select-dropdown {
  max-height: 200px;
  overflow-y: auto;
  outline: none;
}
.options-list {
}
.options-item {
  padding: 0.5rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  cursor: pointer;
  padding-left: 0.9rem;
  outline: none;
  display: flex;

  > :first-child {
    margin-right: 1.5rem;
  }

  &.multi {
    cursor: initial;
  }
}
.selection {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}
.selection-empty {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}
.search {
  display: none;
  height: 44px;

  &.active {
    display: block;
  }

  ::v-deep .input {
    border: none;
    background-color: $hty-white;
    border-bottom: 1px solid $hty-orange;
    border-radius: initial;
  }
}
</style>
