<template>
  <ButtonElement orange @click="toggleSidebar">
    <svg viewBox="0 0 100 80" width="20">
      <rect width="100" height="15" rx="8"></rect>
      <rect y="30" width="100" height="15" rx="8"></rect>
      <rect y="60" width="100" height="15" rx="8"></rect>
    </svg>
  </ButtonElement>
</template>

<script>
import ButtonElement from '@/components/FormControl/ButtonElement.vue';

export default {
  components: { ButtonElement },
  methods: {
    toggleSidebar() {
      const sidebar = document.getElementById('sidebar');

      if (sidebar.classList.contains('sidebar-active')) {
        sidebar.classList.remove('sidebar-active');
      } else {
        sidebar.classList.add('sidebar-active');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  background-color: #e58413;
}

svg rect {
  fill: white;
}
</style>
