export default {
  setLoading: (state, payload) => {
    state.loading = payload;
  },
  setLoggedIn: (state, payload) => {
    state.isLoggedIn = payload;
  },
  setCountries: (state, payload) => {
    state.countries = payload;
  },
  setNotFound: (state, payload) => {
    state.notFound = payload;
  },
  setOfflineBanner: (state, payload) => {
    state.offlineBanner = payload;
  },
  setClients: (state, payload) => {
    state.clients = payload;
  },
  setGlobalSearch: (state, payload) => {
    state.globalSearch = payload;
  },
};
