<template>
  <section class="notification-card" :class="{ active: unread }">
    <section class="icon d-flex align-items-center">
      <slot name="icon" />
    </section>
    <section class="notification-content">
      <section class="info">
        <slot name="info" />
      </section>
      <section class="content">
        <slot name="content" />
      </section>
      <section class="time">
        <slot name="time" />
      </section>
    </section>
  </section>
</template>

<script>
import SlackIcon from '../Svgs/SlackIcon.vue';

export default {
  components: {
    SlackIcon,
  },
  props: {
    unread: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  background: #fdfdfd;
  box-shadow: $hty-shadow;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  border-left: 4px solid white;

  &.active {
    border-left: 4px solid $hty-orange;
  }

  > :first-child {
    margin-right: 1rem;
  }
}
.notification-content {
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
.time {
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2;
  color: $hty-grey-text;
}
.notification-alarm .notification-content {
  .info,
  .content {
    margin-bottom: 5px !important;
  }
}
</style>
