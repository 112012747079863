import { mainAxios, authorizedAxios } from '../config/axios';

export const getNotifications = params =>
  authorizedAxios.get('/api/v1/notifications', { params });

export const markNotificationRead = body =>
  authorizedAxios.post('/api/v1/notifications/mark-read', body);

export const markAllNotificationsRead = body =>
  authorizedAxios.post('/api/v1/notifications/mark-all-read', body);
