import store from '../store';

export const authGuard = (to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;

  if (to.matched.some(route => route.meta.auth) && !isLoggedIn) {
    next({ name: 'Home' });
  } else next();
};

export const guestGuard = (to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;

  if (to.matched.some(route => route.meta.guest) && isLoggedIn) {
    next({ name: 'Dashboard' });
  } else next();
};

export const notFoundGuard = (to, from, next) => {
  if (to.matched.some(route => route.path === '*')) {
    store.dispatch('setNotFound', true);
    next();
  } else next();
};
