export default {
  setNotifications: (state, payload) => {
    state.notifications = payload;
  },
  addNotificationsPage: (state, payload) => {
    state.notifications = [...state.notifications, ...payload];
  },
  setNotificationsPagination: (state, payload) => {
    state.notificationsPagination = payload;
  },
  setNotificationsPaginationCurrentPage: (state, payload) => {
    state.notificationsPagination = {
      ...state.notificationsPagination,
      currentPage: payload,
    };
  },
  setUnreadNotifications: (state, payload) => {
    state.unreadNotifications = payload;
  },
};
