import storage from '../../localStorage';
import router from '../../../router';
import store from '../../../store';

export const authorizationInterceptorRequestSuccess = req => {
  const accessToken = storage.get('access_token');

  req.headers.Authorization = `Bearer ${accessToken}`;

  return req;
};

export const authorizationInterceptorResponseError = err => {
  if (err.response?.status === 401) {
    storage.remove('access_token');
    store.dispatch('setLoggedIn', false);
    router.replace({ name: 'Login' });

    return Promise.reject(
      'Session expired. Please log in again to continue using the app.'
    );
  }

  return Promise.reject(err);
};
