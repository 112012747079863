import { authorizedAxios } from '../../config/axios';
import errorHandler from '../../utils/errorHandler';

export default {
  async fetchCart({ commit }) {
    try {
      const resp = await authorizedAxios.get('/api/v1/cart');
      commit('SET_CART', resp.data.data.cart);
      return resp;
    } catch (err) {
      errorHandler(err);
    }
  },

  async clearCart({ commit }) {
    try {
      const resp = await authorizedAxios.get('/api/v1/clear-cart');
      commit('SET_CART', null);
      return resp;
    } catch (err) {
      errorHandler(err, 'clearCart');
    }
  },

  async addToCart({ commit }, products) {
    try {
      const resp = await authorizedAxios.post(`/api/v1/add-to-cart`, {
        products,
      });
      commit('SET_CART', resp.data.data.cart);
      return resp;
    } catch (err) {
      errorHandler(err, 'addToCart');
    }
  },

  async cartRemoveItem({ commit }, id) {
    try {
      const resp = await authorizedAxios.post(`/api/v1/remove-item/${id}`);
      commit('SET_CART', resp.data.data.cart);
      return resp;
    } catch (err) {
      errorHandler(err, 'cartRemoveItem');
    }
  },

  async cartIncreaseByOne({ commit }, id) {
    try {
      const resp = await authorizedAxios.post(`/api/v1/increase-by-one/${id}`);
      commit('SET_CART', resp.data.data.cart);
      return resp;
    } catch (err) {
      errorHandler(err, 'cartChangeByOne');
    }
  },

  async cartReduceByOne({ commit }, id) {
    try {
      const resp = await authorizedAxios.post(`/api/v1/reduce-by-one/${id}`);
      commit('SET_CART', resp.data.data.cart);
      return resp;
    } catch (err) {
      errorHandler(err, 'cartChangeByOne');
    }
  },
};
