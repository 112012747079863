<template>
  <div class="dropdown-container" :style="positioningStyling">
    <slot />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  setup(props) {
    const positioningStyling = computed(() => {
      return { top: props.top, left: props.left, width: props.width };
    });
    return {
      positioningStyling,
    };
  },
  props: {
    top: {
      type: String,
      default: '0',
    },
    left: {
      type: String,
      default: '0',
    },
    width: {
      type: String,
      default: '300px',
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  border-radius: $hty-radius;
  position: absolute;
  z-index: 20;
  background-color: white;
  box-shadow: $hty-shadow;
}
</style>
