import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VTooltip from 'v-tooltip';
import App from './App.vue';
import router from './router';
import store from './store';
import ResponseAlert from '@/components/Alerts/ResponseAlert';
import XIcon from '@/components/Svgs/XIcon';
import { ValidationObserver, ValidationProvider } from './config/validator';
import i18n from './config/i18n';
import './registerServiceWorker';
import './utils/filters.js';

import ButtonElement from '@/components/FormControl/ButtonElement';
import CheckboxElement from '@/components/FormControl/CheckboxElement';
import InputElement from '@/components/FormControl/InputElement';
import RadioElement from '@/components/FormControl/RadioElement';
import SearchInputElement from '@/components/FormControl/SearchInputElement';
import SelectElement from '@/components/FormControl/SelectElement/SelectElement';
import VueAnalytics from 'vue-analytics';

Vue.use(VueCompositionAPI);
Vue.use(VTooltip);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ResponseAlert', ResponseAlert);
Vue.component('XIcon', XIcon);

Vue.component('ButtonElement', ButtonElement);
Vue.component('CheckboxElement', CheckboxElement);
Vue.component('InputElement', InputElement);
Vue.component('RadioElement', RadioElement);
Vue.component('SearchInputElement', SearchInputElement);
Vue.component('SelectElement', SelectElement);

Vue.config.productionTip = false;
Vue.use(VueAnalytics, {
  id: 'UA-204466709-1',
  router,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
