export default {
  setShowAppointmentCreateModal: (state, payload) => {
    state.showAppointmentCreateModal = payload;
  },
  setShowAppointmentEditModal: (state, payload) => {
    state.showAppointmentEditModal = payload;
  },
  setShowAppointmentDetailsModal: (state, payload) => {
    state.showAppointmentDetailsModal = payload;
  },
  setShowAppointmentCancelModal: (state, payload) => {
    state.showAppointmentCancelModal = payload;
  },
  setShowAppointmentDeleteModal: (state, payload) => {
    state.showAppointmentDeleteModal = payload;
  },
  setShowSuccessfulActionModal: (state, payload) => {
    state.showSuccessfulActionModal = payload;
  },
  setToastAlert: (state, payload) => {
    state.toastAlert = payload;
  },
  setErrorResponse: (state, payload) => {
    state.errorResponse = payload;
  },
};
