import storage from '../../config/localStorage';

const accessToken = storage.get('access_token');

export default {
  isLoggedIn: !!accessToken,
  loading: false,
  countries: [],
  notFound: false,
  offlineBanner: false,
  clients: [],
  globalSearch: {},
};
