import {
  addClientToAppointment,
  cancelAppointment,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  getAppointmentById,
  getAppointments,
} from '../../../services/appointments';
import errorHandler from '../../../utils/errorHandler';

export default {
  async fetchAppointments({ commit, getters }, params = {}) {
    const defaultParams = {
      location: getters.selectedLocation.join(','),
      staff: getters.selectedStaff.join(','),
      sortBy: getters.selectedSort,
      sort: 'asc',
    };

    try {
      commit('setLoading', true);
      commit('setAppointmentsLoaded', false); // rework
      commit('setAppointments', []); // SM: ugly fix to force list rerendering since we need to trigger @ready event
      const { data } = await getAppointments({
        ...defaultParams,
        ...params,
      });
      console.log('data :>> ', data);

      commit('setAppointments', data.data.appointments);
      commit('setAppointmentsLoaded', true);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async fetchAppointmentById(
    { commit, getters },
    { appointmentId, params = {} }
  ) {
    try {
      commit('setLoading', true);
      const { data } = await getAppointmentById(appointmentId, params);
      console.log('data :>> ', data);

      commit('setAppointmentById', data.data.appointment);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async createAppointment({ commit }, payload) {
    try {
      const { data } = await createAppointment(payload);
      commit('setToastAlert', {
        text: data.message,
      });
    } catch (error) {
      errorHandler(error, 'appointmentEdit');
    }
  },
  async updateAppointment({ commit }, payload) {
    try {
      const { data } = await updateAppointment(payload);
      commit('setToastAlert', {
        text: data.message,
      });
    } catch (error) {
      errorHandler(error, 'appointmentEdit');
    }
  },
  async cancelAppointment({ commit, getters }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await cancelAppointment(payload);
      console.log('data :>> ', data);

      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async deleteAppointment({ commit, getters }, appointmentId) {
    try {
      commit('setLoading', true);
      const { data } = await deleteAppointment(appointmentId);
      console.log('data :>> ', data);

      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async addClientToAppointment({ commit, getters }, payload) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);
      const { data } = await addClientToAppointment(payload);
      console.log('data :>> ', data);
    } catch (error) {
      errorHandler(error, 'appointmentAddClient');
    } finally {
      commit('setLoading', false);
    }
  },
};
