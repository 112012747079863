<template>
  <label class="checkbox-container d-flex align-items-center">
    <input
      class="checkbox"
      type="checkbox"
      :value="value"
      @change="handleChange"
      :disabled="readOnly"
      ref="checkboxInput"
    />
    <div
      class="checkbox-custom"
      :class="{
        orange: checked && !readOnly,
        grey: (checked && readOnly) || (checked && color === 'grey'),
        disabled: readOnly,
        empty: !checked,
      }"
    >
      <CheckboxIcon v-if="checked" />
    </div>
    <span v-if="text" class="checkbox-text pl-2" :style="textStyle">
      {{ text }}
    </span>
  </label>
</template>

<script>
import CheckboxIcon from '../Svgs/CheckboxIcon.vue';

export default {
  components: {
    CheckboxIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'orange',
    },
    text: {
      type: String,
      default: null,
    },
    textStyle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  created() {
    this.checked = this.value;
  },
  methods: {
    handleChange() {
      this.checked = !this.checked;
      // console.log('checked :>> ', this.checked);
      this.$emit('input', this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  position: relative;
}
.checkbox {
  position: absolute;
  opacity: 0;
}
.checkbox-text {
  color: #737373;
  font-weight: 500;
  cursor: pointer;
}
.checkbox-custom {
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  min-width: 20px;
  border: $hty-border;
  border-radius: 4px;

  > svg {
    position: absolute;
    left: 3px;
    top: 5px;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $hty-grey-border;
    border-color: $hty-grey-border !important;
    pointer-events: none;
  }
  &.orange,
  &.empty {
    border-color: $hty-orange;
  }

  &.orange {
    background-color: $hty-orange;
  }

  &.grey {
    background-color: $hty-grey;
  }
}
</style>
