<template>
  <div class="container-fluid" style="height: 100vh; overflow-x: auto">
    <div class="offline" :class="{ active: $store.getters.offlineBanner }">
      You are currently offline
    </div>

    <div
      v-if="$store.getters.userInfo.email"
      class="main-layout row"
      style="height: 100%; overflow-x: auto"
    >
      <section id="sidebar" class="sidebar col-auto d-none d-lg-block px-0">
        <Sidebar />
      </section>
      <section class="main col px-0" style="overflow: auto">
        <div class="header">
          <Header />
        </div>
        <div class="content" @click="closeSidebar">
          <!-- <VerificationPendingAlert></VerificationPendingAlert> -->
          <RouterView />
        </div>
      </section>
    </div>

    <ToastAlert />
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from '@vue/composition-api';
import Sidebar from '../components/Sidebar/Sidebar.vue';
import Header from '../components/Header/Header.vue';
import store from '../store';
// import VerificationPendingAlert from '@/components/Alerts/VerificationPendingAlert';
import ToastAlert from '@/components/Alerts/ToastAlert';

export default {
  setup() {
    onMounted(() => {
      store.dispatch('fetchUserInfo');
      store.dispatch('fetchNotifications');
    });
  },
  methods: {
    closeSidebar() {
      const sidebar = document.getElementById('sidebar');

      if (sidebar.classList.contains('sidebar-active')) {
        sidebar.classList.remove('sidebar-active');
      }
    },
  },
  components: {
    Header,
    Sidebar,
    // VerificationPendingAlert,
    ToastAlert,
  },
};
</script>

<style lang="scss" scoped>
.offline {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  height: 60px;
  text-align: center;
  padding: 20px;
  background-color: orange;
  z-index: 20;
  transition: all 200ms;

  &.active {
    top: 0;
    transition: all 200ms;
  }
}

.main-layout {
  // display: grid;
  // grid-template-columns: 350px 1fr;
  // grid-template-rows: 110px 1fr;
  // grid-template-areas:
  //   's h'
  //   's c';
  // height: 100vh;
  // width: 100%;
}
.sidebar {
  background-color: #181818;
  // position: relative;
  // bottom: 0;
  // top: 0;
  // left: 0;
  // width: 350px;
  // height: 100%;
  // overflow-y: auto;
  // grid-area: s;
}
.sidebar-active {
  display: block !important;
}
.header {
  // grid-area: h;
  // height: 100%;
  // width: 100%;
  border-bottom: $hty-border;
  // display: flex;
  // align-items: center;
}
.content {
  // grid-area: c;
}
.main {
  // width: calc(100vw - 350px);
}
</style>
