var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.anchor)?_c('RouterLink',{staticClass:"anchor button",class:{
    grey: _vm.grey,
    orange: _vm.orange,
    'orange-secondary': _vm.orangeSecondary,
    'grey-secondary': _vm.greySecondary,
    link: _vm.link,
    disabled: _vm.disabled,
    block: _vm.block,
  },style:(_vm.style),attrs:{"to":_vm.anchor,"event":!_vm.disabled ? 'click' : ''}},[_vm._t("default",[_vm._v(_vm._s(_vm.text))])],2):_c('button',{staticClass:"button",class:{
    grey: _vm.grey,
    orange: _vm.orange,
    'orange-secondary': _vm.orangeSecondary,
    'grey-secondary': _vm.greySecondary,
    link: _vm.link,
    disabled: _vm.disabled,
    block: _vm.block,
  },style:(_vm.style),attrs:{"type":_vm.type,"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('click')}}},[(!_vm.loading)?[_vm._t("default",[_vm._v(_vm._s(_vm.text))])]:[_c('img',{attrs:{"width":"50","src":require('@/assets/svg/spinner_white.svg')}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }