export const serviceBookAppointmentPayload = () => ({
  companyId: '',
  service: '',
  dateStart: '',
  timeStart: '',
  timeEnd: '',
  staffId: '',
});

export default {
  serviceType: '',
  serviceCategories: [],
  serviceCompanies: [],
  serviceCompanyDetails: [],
  serviceSelectedService: {},
  serviceBookAppointmentPayload: serviceBookAppointmentPayload(),
};
