<template>
  <div
    v-if="$store.getters.toastAlert"
    v-text="$store.getters.toastAlert.text"
    class="toast-alert px-5 py-3"
    :class="$store.getters.toastAlert.class || 'success'"
  ></div>
</template>

<script>
export default {
  watch: {
    '$store.getters.toastAlert': function (val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit('setToastAlert', false);
        }, val.duration || 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -145px;
}

.success {
  font-size: 20px;
  background: #e58413;
  border-radius: 10px;
  color: white;
  transition: 0.1s height, opacity;
  border: 6px solid rgba($color: rgb(82, 82, 82), $alpha: 0.7);
}
</style>
