import store from '../store';

export default (error, action = '') => {
  // console.log('error :>> ', error, error.response, error?.message);

  store.commit('setErrorResponse', {
    action: action,
    data: error.response.data,
  });

  //offline handler
  if (error.message.includes('Network Error')) {
    store.dispatch('setOfflineBanner');
  }

  throw new Error(error);
};
