import { authorizedAxios } from '../config/axios';

export const getAppointments = params =>
  authorizedAxios.get('/api/v1/appointments', { params });

export const getAppointmentById = (appointmentId, params) =>
  authorizedAxios.get(`/api/v1/appointments/${appointmentId}`, { params });

export const createAppointment = body =>
  authorizedAxios.post('/api/v1/appointments', body);

export const updateAppointment = ({ id, payload }) =>
  authorizedAxios.put(`/api/v1/appointments/${id}`, payload);

export const cancelAppointment = body =>
  authorizedAxios.post('/api/v1/appointment-cancel', body);

export const deleteAppointment = appointmentId =>
  authorizedAxios.delete(`/api/v1/appointments/${appointmentId}`);

export const addClientToAppointment = body =>
  authorizedAxios.post('/api/v1/add-client-to-appointment', body);
