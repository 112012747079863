import {
  getServiceCategories,
  getServicesAndSubcategoriesByCategoryIds,
} from '../../../services/categories';
import { getCompanyDetails } from '../../../services/general';
import errorHandler from '../../../utils/errorHandler';

export default {
  async fetchServiceCategories({ getters, commit }) {
    try {
      commit('setLoading', true);
      const { data } = await getServiceCategories({
        type: getters.serviceType,
      });
      console.log('data :>> ', data);

      commit('setServiceCategories', data.data.data);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async fetchServiceCompanies({ commit }, params) {
    try {
      commit('setLoading', true);
      const { data } = await getServicesAndSubcategoriesByCategoryIds(params);
      console.log('data :>> ', data);

      commit('setServiceCompanies', data.data.data);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async fetchServiceCompanyDetails({ commit }, companyId) {
    try {
      commit('setLoading', true);
      const { data } = await getCompanyDetails(companyId);
      console.log('data :>> ', data);

      commit('setServiceCompanyDetails', data.data.data);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
};
