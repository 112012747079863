import { mainAxios, authorizedAxios } from '../config/axios';

export const getCountries = () => mainAxios.get(`/api/v1/countries`);

export const searchClients = params =>
  authorizedAxios.get(`/api/v1/search-clients`, { params });

export const getCompanyDetails = companyId =>
  authorizedAxios.get(`/api/v1/get-company/${companyId}`);

export const contactForm = data =>
  authorizedAxios.post('/api/v1/contact-us', data);

export const globalSearch = params =>
  authorizedAxios.get('/api/v1/search-query', { params });
