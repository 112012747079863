import storage from '../../../../config/localStorage';
import errorHandler from '../../../../utils/errorHandler';
import {
  forgotPasswordStaffUser,
  getAllStaffUsers,
  resetPasswordStaffUser,
  staffUserLogin,
  getStaffUser,
  addStaffUser,
  updateStaffUser,
  deleteStaffUser,
} from '../../../../services/users/staff';

export default {
  async loginStaffUser({ commit }, { validator, ...payload }) {
    try {
      commit('setLoading', true);
      const { data } = await staffUserLogin(payload);
      storage.set('access_token', data.data.authToken);
      commit('setLoggedIn', true);
    } catch (error) {
      if (error?.response?.data.message) {
        validator.setErrors({
          email: [error.response.data.message],
          password: [error.response.data.message],
        });
      }
      errorHandler(error, 'login');
    } finally {
      commit('setLoading', false);
    }
  },
  async forgotPasswordStaffUser({ commit }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await forgotPasswordStaffUser(payload);
      commit('setToastAlert', { text: data.message });
    } catch (error) {
      errorHandler(error, 'forgotPassword');
    } finally {
      commit('setLoading', false);
    }
  },
  async resetPasswordStaffUser({ commit }, payload) {
    try {
      commit('setLoading', true);
      await resetPasswordStaffUser(payload);
    } catch (error) {
      errorHandler(error, 'resetPassword');
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchAllStaffUsers({ commit }, params) {
    try {
      commit('setLoading', true);
      const { data } = await getAllStaffUsers(params);
      commit('setStaffUsers', data.data.staff);
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchStaffUser({ commit }, id) {
    try {
      commit('setLoading', true);
      const { data } = await getStaffUser(id);
      commit('setStaffUsers', data.data.staff);
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async addStaffUser({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);
      await addStaffUser(payload);

      commit('setShowSuccessfulActionModal', true);
      dispatch('fetchAllStaffUsers');
    } catch (error) {
      errorHandler(error, 'staffEdit');
    } finally {
      commit('setLoading', false);
    }
  },
  async updateStaffUser({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);
      await updateStaffUser(payload.id, payload.data);
      commit('setShowSuccessfulActionModal', true);
      dispatch('fetchAllStaffUsers');
    } catch (error) {
      errorHandler(error, 'staffEdit');
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteStaffUser({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);
      await deleteStaffUser(payload.id);
      dispatch('fetchAllStaffUsers');
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
