<template>
  <div class="sidebar-container">
    <!-- Logo -->
    <section class="logo">
      <img
        style="max-width: 188px"
        :src="require('@/assets/pics/logo_white.png')"
      />
    </section>

    <!-- Items -->
    <section class="items">
      <div
        v-for="item of items.filter(i => i.show)"
        :key="item.text"
        class="item-container"
      >
        <div
          @click="handleSelectSection(item)"
          role="button"
          tabindex="0"
          class="item"
          :class="{ active: isActive(item) }"
        >
          <span class="icon" :class="{ active: isActive(item) }">
            <Component
              :is="item.icon"
              :color="isActive(item) ? '#e58413' : 'white'"
            />
          </span>
          <span class="text" :class="{ active: isActive(item) }">
            {{ $t(item.text) }}
          </span>
          <div v-if="isActive(item)" class="isle"></div>
        </div>

        <div
          class="subitems"
          v-show="isDropdownActive(item) || isDropdownActive2()"
          v-if="item.children"
        >
          <div
            class="item"
            v-for="subItem of item.children.filter(i => i.show)"
            :key="item.text + subItem.text"
            @click.stop="handleSelectSection(subItem)"
            role="button"
          >
            <span class="icon"></span>
            <span class="text" :class="{ active: isActive(subItem) }">
              {{ $t(subItem.text) }}
            </span>
            <div v-if="isActive(subItem)" class="isle"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import LogoIcon from '../Svgs/LogoIcon.vue';
// import HomeIcon from '../Svgs/Sidebar/HomeIcon';
import CalendarIcon from '../Svgs/Sidebar/CalendarIcon.vue';
// import HornIcon from '../Svgs/Sidebar/HornIcon.vue';
// import ClientsIcon from '../Svgs/Sidebar/ClientsIcon.vue';
// import ReportsIcon from '../Svgs/Sidebar/ReportsIcon.vue';
// import HeartIcon from '../Svgs/Sidebar/HeartIcon.vue';
// import SocialProjects from '../Svgs/Sidebar/SocialProjects.vue';
import SettingsIcon from '../Svgs/Sidebar/SettingsIcon.vue';
// import DollarSignIcon from '../Svgs/Sidebar/DollarSignIcon.vue';
import PhoneIcon from '../Svgs/Sidebar/PhoneIcon.vue';
import UserIcon from '../Svgs/Sidebar/UserIcon.vue';
import ServicesIcon from '../Svgs/Sidebar/ServicesIcon.vue';
import ProductsIcon from '../Svgs/Sidebar/ProductsIcon.vue';
import router from '../../router';
import store from '../../store';
import { dateFormat } from '../../utils/dateTimeHelpers';

export default {
  setup() {
    const closeSidebar = function () {
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.remove('sidebar-active');
    };

    const items = computed(() => [
      {
        icon: CalendarIcon,
        text: 'menu.calendar',
        show: true,
        routeName: 'Calendar',
        action: () => {
          router.push({
            name: 'Calendar',
            params: { view: 'grid', period: 'day' },
            query: { selectedDate: dateFormat(Date.now(), 'y-MM-dd') },
          });
          closeSidebar();
        },
      },
      {
        icon: ServicesIcon,
        text: 'menu.services',
        show: store.getters.isEndUser,
        routeName: 'ServiceCategories',
        action: () => {
          router.push({
            name: 'Services',
            props: { type: 'service_provider' },
          });
          closeSidebar();
        },
      },
      {
        icon: ProductsIcon,
        text: 'menu.products',
        show: store.getters.isEndUser,
        routeName: 'ProductsCategories',
        action: () => {
          router.push({
            name: 'ProductsCategories',
          });
          closeSidebar();
        },
      },
      {
        icon: SettingsIcon,
        text: 'menu.settings',
        show:
          store.getters.isAdminServiceProvider || store.getters.isAdminRetailer,
        children: [
          {
            text: 'serviceProvider.settingsCompany.company',
            show: true,
            routeName: 'SettingsCompany',
            action: () => {
              router.push({
                name: 'SettingsCompany',
              });
              closeSidebar();
            },
          },
          {
            text: 'menu.services',
            show: store.getters.isAdminServiceProvider,
            routeName: 'SettingsServices',
            action: () => {
              router.push({
                name: 'SettingsServices',
              });
              closeSidebar();
            },
          },
          {
            text: 'menu.termins',
            show: store.getters.isAdminRetailer,
            routeName: 'SettingsTerminen',
            action: () => {
              router.push({
                name: 'SettingsTerminen',
              });
              closeSidebar();
            },
          },
          {
            text: 'menu.products',
            show: true,
            routeName: 'SettingsProducts',
            action: () => {
              router.push({
                name: 'SettingsProducts',
              });
              closeSidebar();
            },
          },
          {
            text: 'menu.staff',
            show: store.getters.isAdminServiceProvider,
            routeName: 'SettingsStaff',
            action: () => {
              router.push({
                name: 'SettingsStaff',
              });
              closeSidebar();
            },
          },
        ],
      },
      {
        icon: UserIcon,
        show: store.getters.isEndUser,
        text: 'menu.account',
        routeName: 'SettingsProfile',
        action: () => {
          router.push({ name: 'SettingsProfile' });
          closeSidebar();
        },
      },
      // {
      //   icon: SocialProjects,
      //   show: true,
      //   text: 'menu.socialProjects',
      //   routeName: 'SocialProjects',
      //   action: () => {
      //     router.push({ name: 'SocialProjects' });
      //     closeSidebar();
      //   },
      // },
      {
        icon: PhoneIcon,
        show: true,
        text: 'menu.contact',
        routeName: 'Contact',
        action: () => {
          router.push({ name: 'Contact' });
          closeSidebar();
        },
      },
    ]);
    const selectedItem = ref(null);
    const selectedItemDropdown = ref(null);

    const handleSelectSection = item => {
      if (item.children && item.children.length) {
        selectedItemDropdown.value = item.text.toLowerCase();
      } else {
        selectedItem.value = item.text.toLowerCase();
        item.action();
      }
    };

    const isDropdownActive = item => {
      return selectedItemDropdown.value === item.text.toLowerCase();
    };

    return {
      items,
      handleSelectSection,
      isDropdownActive,
    };
  },
  methods: {
    isActive(item) {
      return item.routeName == this.$route.name;
    },
    isDropdownActive2() {
      return this.$route.name?.startsWith('Settings');
    },
  },
  components: { LogoIcon },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  width: 300px;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  height: 220px;
}

.items {
  // margin-top: 2rem;
}

.item {
  // background-color: grey;
  height: 3rem;
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
}
.item {
  // background-color: grey;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  position: relative;
  overflow-x: hidden;

  &.active {
    background-color: rgba(229, 132, 19, 0.1);
  }
}

.items-container {
  height: auto;
}

.icon {
  width: 24px;
}

.text {
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  margin-left: 1rem;

  &.active {
    color: $hty-orange;
  }
}

.isle {
  position: absolute;
  right: -5px;
  background-color: #e58413;
  height: 100%;
  width: 10px;
  border-radius: 50%;
}
</style>
