export default {
  companyServices: state => state.companyServices,
  companyLocations: state => state.companyLocations,
  companyCategories: state => state.companyCategories,
  companyCategoriesIds: state => {
    const categories = [];
    const subcategories = [];

    state.companyCategories.forEach(c => {
      if (!categories.includes(c.categoryId)) {
        categories.push(c.categoryId);
      }
      if (!subcategories.includes(c.subcategoryId)) {
        subcategories.push(c.subcategoryId);
      }
    });

    return {
      categories,
      subcategories,
    };
  },
  companySubcategoriesOnly: state => {
    const result = [];

    state.companyCategories.forEach(c => {
      const subCat = {
        id: c.subcategoryId,
        name: c.subcategoryName,
      };

      if (!result.find(el => el.id == subCat.id)) {
        result.push(subCat);
      }
    });

    return result;
  },
  companyServiceCategories: state => {
    return state.companyServiceCategories;
  },
  companyTypeDocs: state => state.companyTypeDocs,
  companyServicesOptions: state =>
    state.companyServices.map(item => ({
      label: item.name,
      value: item.id,
    })),
  companyLocationsOptions: state =>
    state.companyLocations.map(item => ({
      label: item.address,
      value: item.id,
    })),
};
