<template>
  <div
    @click="isOpen = blockOpen ? isOpen : !isOpen"
    role="button"
    tabindex="0"
    class="notification-alarm"
  >
    <BellIcon />
    <div v-if="unread" class="unread-indicator">
      <span>
        {{ unread }}
      </span>
    </div>
    <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
      <DropdownElement v-if="isOpen" top="60px" left="-370px" width="450px">
        <div class="dropdown">
          <div class="dropdown-header-custom p-3">
            <div class="title mb-0">
              {{
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.notification.notifications`
                )
              }}
            </div>
            <ButtonElement
              @click="$store.dispatch('markAllNotificationsRead')"
              link
              class="action"
            >
              {{
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.notification.markAllAsRead`
                )
              }}
            </ButtonElement>
          </div>
          <div class="list">
            <div
              v-for="item of $store.getters.notifications.slice(0, 3)"
              :key="item.id"
              class="list-item"
            >
              <NotificationCard :unread="!item.readAt">
                <template #icon>
                  <NotificationIcon :type="item.type" />
                </template>
                <template #info>
                  <NotificationMessageFormat
                    :data="item.data.title"
                    type="title"
                  />
                </template>
                <template #time>
                  {{ timeDuration(new Date(item.updatedAt)) }}
                </template>
              </NotificationCard>
            </div>
          </div>
        </div>
        <div
          @click="$router.push({ name: 'Notifications' })"
          class="footer"
          role="button"
          tabindex="0"
        >
          {{
            $t(`${$store.getters.getTranslationKeyForAdmin}.dashboard.viewAll`)
          }}
        </div>
      </DropdownElement>
    </OutsideClickContainer>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import BellIcon from '../Svgs/BellIcon.vue';
import DropdownElement from '../Dropdown/DropdownElement.vue';
import ButtonElement from '../FormControl/ButtonElement.vue';
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer.vue';
import NotificationCard from '../Notifications/NotificationCard.vue';
import { timeDuration } from '../../utils/dateTimeHelpers';
import NotificationMessageFormat from '../Notifications/NotificationMessageFormat.vue';
import NotificationIcon from '../Notifications/NotificationIcon.vue';

export default {
  setup() {
    const isOpen = ref(false);
    const listItems = ref(['sdsdd', 'sd', 'dfdf', 'fgfg']);

    return {
      listItems,
      isOpen,
      timeDuration,
      blockOpen: false,
    };
  },
  watch: {
    isOpen(val) {
      if (!val) {
        this.blockOpen = true;
        setTimeout(() => {
          this.blockOpen = false;
        }, 100);
      }
    },
  },
  components: {
    NotificationIcon,
    NotificationMessageFormat,
    NotificationCard,
    OutsideClickContainer,
    ButtonElement,
    DropdownElement,
    BellIcon,
  },
  props: {
    unread: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 575px) {
  .dropdown-container {
    position: fixed !important;
    top: 100px !important;
    left: unset !important;
    right: 1rem;
    width: 90vw !important;
  }
}

.notification-alarm {
  background: #fafafa;
  border-radius: 10px;
  padding: 0.9rem;
  position: relative;
  cursor: pointer;
}
.unread-indicator {
  background-color: $hty-orange;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-size: 0.8rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
.dropdown {
  overflow-y: hidden;
  box-shadow: $hty-shadow-strong;
}
.dropdown-header-custom {
  display: flex;
  justify-content: space-between;
  // padding-bottom: 1.2rem;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2;
  color: $hty-orange;
}
.action {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-orange;
}
.footer {
  background-color: $hty-light-orange2;
  border-radius: 0px 0px 10px 10px;
  color: $hty-orange;
  text-align: center;
  padding: 0.6rem;
  position: relative;
  z-index: 5;
  cursor: pointer;
}
.list {
  // height: 200px;
}
.list-item {
  border-bottom: 1px solid $hty-grey-border;
  // height: 5rem;
  display: flex;
  align-items: center;
}
</style>
