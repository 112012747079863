import { getTimeSlot } from '../../utils/dateTimeHelpers';

export default {
  userInfo: state => state.userInfo,
  userCompanyDocs: state => JSON.parse(state.userInfo.companyDocs),
  workingHours: state => {
    let parsedHours = null;
    if (state.userInfo.workingHours) {
      try {
        parsedHours = JSON.parse(state.userInfo.workingHours);
      } catch (e) {
        parsedHours = state.userInfo.workingHours;
      }
    }
    return parsedHours;
  },
  isAdminUserVerified: state => {
    const user = state.userInfo;

    if (user.role != 'admin') {
      return true;
    }

    return user.docsVerified;
  },
  isAdminServiceProvider: state =>
    state.userInfo.role === 'admin' &&
    state.userInfo.type === 'service_provider',
  isAdminRetailer: state =>
    state.userInfo.role === 'admin' && state.userInfo.type === 'retailer',
  isEndUser: state => state.userInfo.role === 'user',
  getTranslationKeyForAdmin: state =>
    state.userInfo.type === 'service_provider'
      ? 'serviceProvider'
      : state.userInfo.type === 'retailer'
      ? 'retailer'
      : state.userInfo.role === 'user'
      ? 'enduser'
      : '',
  adminRegistrationData: state => state.adminRegistrationData,
  companyStaff: state => state.companyStaff,
  staffUsers: state => state.staffUsers,
  staffUsersFormatted: state =>
    state.staffUsers.map(item => ({
      ...item,
      workingHours: JSON.parse(item.workingHours),
    })),
  staffUsersOptions: state =>
    state.staffUsers.map(item => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    })),
  companyStaffOptions: state =>
    state.companyStaff.map(item => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    })),

  userVerifyPhone: state => state.userVerifyPhone,
  categoryArray: state => {
    return state.adminRegistrationData.category
      ? state.adminRegistrationData.category.split(',')
      : [];
  },
  subcategoryArray: state => {
    return state.adminRegistrationData.subcategory
      ? state.adminRegistrationData.subcategory.split(',')
      : [];
  },
  userVerifyPhoneFullNumber: state =>
    state.userVerifyPhone.callingCode + state.userVerifyPhone.phoneNumber,
  companyAppointments: state => state.companyAppointments,
  companyUniqueAppointments: state => {
    let seen = {};
    const results = state.companyAppointments.filter(o => {
      let key = ['dateStart', 'timeStart', 'timeEnd'].map(k => o[k]).join('|');
      if (!seen[key]) {
        seen[key] = true;
        return true;
      }
    });
    return results;
  },
  companyBookedAppointments: state =>
    state.companyAppointments.filter(i => i.confirmed === 1),
  isAppointmentAlreadyBooked: state => (date, timeStart, timeEnd, limit) => {
    const appointments = state.companyAppointments.filter(
      i =>
        i.clients &&
        i.clients.length &&
        i.dateStart === date &&
        i.timeStart === timeStart &&
        i.timeEnd === timeEnd
    );
    let userAlreadyBooked = false,
      bookedTimes = 0;
    for (let a of appointments) {
      if (parseInt(a.confirmed) === 1) bookedTimes++;
      if (a.clients.map(i => i.id).includes(state.userInfo.id))
        userAlreadyBooked = true;
    }
    return bookedTimes >= limit || userAlreadyBooked;
  },
  isAppointmentAlreadyBookedForStaff: state => (
    date,
    timeStart,
    timeEnd,
    staffId
  ) => {
    return !!state.companyAppointments.find(
      apt =>
        apt.dateStart == date &&
        apt.timeStart == timeStart &&
        apt.timeEnd == timeEnd &&
        apt.staffId == staffId
    );
  },
  placesLeftOnAppointment: state => (date, timeStart, timeEnd, limit) => {
    const appointments = state.companyAppointments.filter(
      i =>
        i.clients &&
        i.clients.length &&
        i.dateStart === date &&
        i.timeStart === timeStart &&
        i.timeEnd === timeEnd
    );
    let bookedTimes = 0;
    for (let a of appointments) {
      if (parseInt(a.confirmed) === 1) bookedTimes++;
    }
    return limit - bookedTimes;
  },
  availableCompanyAppointments: state =>
    state.companyAppointments.filter(i => parseInt(i.confirmed) === 0),
  availableCompanyAppointmentDates: (state, getters) =>
    getters.availableCompanyAppointments
      .map(i => i.dateStart)
      .sort((a, b) => new Date(a) - new Date(b)),
  availableCompanyAppointmentSlotsByDate: (state, getters) => date =>
    getters.companyUniqueAppointments
      .filter(i => i.dateStart === date)
      .sort((a, b) => a.timeStart.localeCompare(b.timeStart))
      .map(i => ({
        slot: getTimeSlot(i.timeStart, i.timeEnd),
        booked:
          parseInt(i.confirmed) === 1 &&
          getters.isAppointmentAlreadyBooked(
            i.dateStart,
            i.timeStart,
            i.timeEnd,
            i.limit
          ),
        placesLeft: getters.placesLeftOnAppointment(
          i.dateStart,
          i.timeStart,
          i.timeEnd,
          i.limit
        ),
      })),
};
