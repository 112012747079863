export default {
  async saveAppointment({ dispatch, commit }, payload) {
    await dispatch('createAppointment', payload);
    commit('setAppointments', []); //SM: fucking ugly hack to force @ready vuecal event, so list view also updates
    dispatch('fetchAppointments');
    commit('setShowAppointmentCreateModal', false);
  },
  async editAppointment({ dispatch, commit }, payload) {
    await dispatch('updateAppointment', payload);
    commit('setAppointments', []); //SM: fucking ugly hack to force @ready vuecal event, so list view also updates
    dispatch('fetchAppointments');
    commit('setShowAppointmentEditModal', false);
  },
  async handleCancelAppointment({ dispatch, commit }, payload) {
    await dispatch('cancelAppointment', payload);
    commit('setAppointments', []);
    dispatch('fetchAppointments');
    commit('setShowAppointmentDetailsModal', false);
  },
  async handleDeleteAppointment({ dispatch, commit }, payload) {
    await dispatch('deleteAppointment', payload);
    commit('setAppointments', []);
    dispatch('fetchAppointments');
    commit('setShowAppointmentDetailsModal', false);
  },
};
