import errorHandler from '../../../utils/errorHandler';
import { authorizedAxios, mainAxios } from '../../../config/axios';

export default {
  async createServiceCategory({ commit }, payload) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);

      const resp = await authorizedAxios.post('/api/v1/category', payload);

      commit('setShowSuccessfulActionModal', true);
      return resp;
    } catch (error) {
      errorHandler(error, 'createServiceCategory');
    } finally {
      commit('setLoading', false);
    }
  },
  async createProductCategory({ commit }, payload) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);

      const resp = await authorizedAxios.post(
        '/api/v1/product-category',
        payload
      );

      commit('setShowSuccessfulActionModal', true);
      return resp;
    } catch (error) {
      errorHandler(error, 'createProductCategory');
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCategories({ commit }, params) {
    try {
      commit('setLoading', true);

      const resp = await mainAxios.get('/api/v1/categories', { params });

      commit('setCategories', resp.data.data.data);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchSubcategoriesByCategoryIds({ commit }, catIds) {
    try {
      commit('setLoading', true);

      const resp = await mainAxios.get(`/api/v1/categories-sub-categories`, {
        params: { catIds },
      });

      commit('setSubcategories', resp.data.data.data);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
