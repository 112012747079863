<template>
  <div class="search">
    <SearchIcon />
    <InputElement
      v-model="searchInput"
      class="search-input"
      focus
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import { toRef, watchEffect } from '@vue/composition-api';
import InputElement from '../FormControl/InputElement.vue';
import SearchIcon from '../Svgs/SearchIcon.vue';
import useFilterData from '../../composables/useFilterData';

export default {
  setup(props, ctx) {
    const [filtered, searchInput] = useFilterData(
      toRef(props, 'data'),
      props.filterOptions
    );

    watchEffect(() => {
      ctx.emit('filtered-data', filtered.value);
      ctx.emit('input', searchInput.value);
    });

    return {
      searchInput,
    };
  },
  components: {
    SearchIcon,
    InputElement,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;
  // margin-left: 20px;

  > :first-child {
    position: absolute;
    left: 1rem;
    top: 0.7rem;
  }

  > .search-input {
    ::v-deep input {
      padding-left: 3rem;
    }
  }
}
</style>
