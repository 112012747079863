export const intlShortDateTimeFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
  timeStyle: 'short',
});
export const intlShortDateFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'short',
});
export const intlMonthYearDateFormat = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'long',
});
export const intlDayFormat = new Intl.DateTimeFormat(undefined, {
  day: '2-digit',
});
export const intlRelativeTimeFormat = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
});

export const intlNumberFormat = new Intl.NumberFormat();

export const intlDefaultCurrencyFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2,
});

const currencyObjects = {};

export const intlCurrencyFormat = (currency, fraction) => {
  if (
    !currencyObjects[currency] ||
    currencyObjects[currency].resolvedOptions().minimumFractionDigits !==
      fraction
  ) {
    currencyObjects[currency] = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: fraction,
    });
  }
  return currencyObjects[currency];
};
