export default {
  setCompanyServices: (state, payload) => {
    state.companyServices = payload;
  },
  setCompanyLocations: (state, payload) => {
    state.companyLocations = payload;
  },
  setCompanyCategories: (state, payload) => {
    state.companyCategories = payload;
  },
  setCompanyServiceCategories: (state, payload) => {
    state.companyServiceCategories = payload;
  },
  setCompanyTypeDocs: (state, payload) => {
    state.companyTypeDocs = payload;
  },
};
