<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 4.32861H5C3.89543 4.32861 3 5.22404 3 6.32861V20.3286C3 21.4332 3.89543 22.3286 5 22.3286H19C20.1046 22.3286 21 21.4332 21 20.3286V6.32861C21 5.22404 20.1046 4.32861 19 4.32861Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 2.32861V6.32861"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 2.32861V6.32861"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 12H21"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="scss" scoped></style>
