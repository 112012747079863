<template>
  <div>
    <div class="offline" :class="{ active: $store.getters.offlineBanner }">
      You are currently offline
    </div>
    <RouterView name="notFoundView" />
  </div>
</template>

<script>
import { ref, watchEffect } from '@vue/composition-api';

export default {};
</script>

<style lang="scss" scoped>
.offline {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  height: 60px;
  text-align: center;
  padding: 20px;
  background-color: orange;
  z-index: 20;
  transition: all 200ms;

  &.active {
    top: 0;
    transition: all 200ms;
  }
}
</style>
