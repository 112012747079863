export default {
  showAppointmentCreateModal: false,
  showAppointmentEditModal: false,
  showAppointmentDetailsModal: false,
  showAppointmentCancelModal: false,
  showAppointmentDeleteModal: false,
  showSuccessfulActionModal: false,
  toastAlert: false,
  errorResponse: {},
};
