<template>
  <div class="">
    <form class="search" @submit.prevent="">
      <SearchIcon />
      <InputElement
        ref="input"
        v-model="searchInput"
        class="search-input"
        focus
        :placeholder="placeholder"
        @keyup="handleKeyup"
        @input="handleInput"
      />
      <OutsideClickContainer @outsideClick="closeSearch" :active="isOpen">
        <DropdownElement v-if="isOpen" top="60px" width="100%">
          <div class="dropdown">
            <div class="dropdown-title px-4 pb-2">
              {{ $t(`general.searchResult`) }}
            </div>
            <template v-if="!$store.getters.loading">
              <div
                v-for="(item, i) of searchResult"
                :key="i"
                class="dropdown-item py-2 d-flex align-items-center justify-content-between"
                role="button"
                tabindex="0"
                @click="item.click"
              >
                <div class="text">{{ item.label }}</div>
                <div>
                  <BannerMessage> {{ item.type }} </BannerMessage>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <img
                width="100"
                :src="require('@/assets/svg/spinner_black.svg')"
              />
            </div>
          </div>
        </DropdownElement>
      </OutsideClickContainer>
    </form>
  </div>
</template>

<script>
import InputElement from '../FormControl/InputElement.vue';
import SearchIcon from '../Svgs/SearchIcon.vue';
import DropdownElement from '../Dropdown/DropdownElement.vue';
import BannerMessage from '../Banner/BannerMessage.vue';
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer.vue';
import { debounce } from 'lodash';
import store from '@/store';

export default {
  data() {
    return {
      searchInput: '',
      isOpen: false,
    };
  },
  computed: {
    searchResult() {
      const result = [];

      this.$store.getters.globalSearch.companies?.forEach(el => {
        result.push({
          label: el.companyName,
          type: 'Unternehmen',
          click: () => {
            this.$router.push({
              name:
                el.type === 'retailer'
                  ? 'RetailerBookAppointment'
                  : 'ServiceBookAppointment',
              params: { id: el.id },
            });
            this.closeSearch();
          },
        });
      });

      this.$store.getters.globalSearch.categories?.forEach(el => {
        result.push({
          label: el.name,
          type: 'Kategorie',
          click: () => {
            this.$router.push({
              name:
                el.type === 'retailer'
                  ? 'ProductCategoryDetails'
                  : 'ServiceCategoryDetails',
              params: { id: el.id },
            });
            this.closeSearch();
          },
        });
      });

      this.$store.getters.globalSearch.services?.forEach(el => {
        result.push({
          label: el.name,
          type: 'Dienstleistung',
          click: () => {
            if (el.companyId) {
              this.$router.push({
                name:
                  el.companyType === 'retailer'
                    ? 'RetailerBookAppointment'
                    : 'ServiceBookAppointment',
                params: { id: el.companyId },
              });
            }

            this.closeSearch();
          },
        });
      });

      return result;
    },
  },
  methods: {
    handleKeyup(key) {
      if (key === 'Escape') {
        this.closeSearch();
      }
    },
    handleInput(value) {
      if (!value) {
        return this.closeSearch();
      }

      this.isOpen = true;
      this.fetchData(value);
    },
    fetchData: debounce(value => {
      store.dispatch('globalSearch', { query: value });
    }, 500),
    closeSearch() {
      this.isOpen = false;
      this.searchInput = '';
      this.$store.commit('setGlobalSearch', {});
    },
  },
  components: {
    OutsideClickContainer,
    DropdownElement,
    SearchIcon,
    InputElement,
    BannerMessage,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Suche',
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;

  > :first-child {
    position: absolute;
    left: 1rem;
    top: 0.7rem;
  }

  > .search-input {
    ::v-deep input {
      padding-left: 3rem;
      background-color: $hty-grey;
      border-color: $hty-grey;
    }
  }
}
.dropdown {
  & .dropdown-item:last-child:hover {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.dropdown-title {
  border-bottom: 2px solid $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-grey-text;
}
.dropdown-item {
  &:active {
    background-color: $hty-orange;
  }

  .text {
    @include hty-ellipsis;
    width: 70%;
  }
}
</style>
