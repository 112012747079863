import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { authGuard, guestGuard, notFoundGuard } from './guards';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authGuard);
router.beforeEach(guestGuard);
router.beforeEach(notFoundGuard);

export default router;
