<template>
  <img
    :src="require('@/assets/pics/logo_white.png')"
    :style="'width:' + width"
  />
  <!-- <svg
    width="172"
    height="44"
    viewBox="0 0 172 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M172 19.0372H159.226L155.299 16.531V3.51826H164.892V12.1934H168.968V3.0845C168.968 1.38098 167.544 0 165.787 0H154.454C152.697 0 151.273 1.38098 151.273 3.0845V15.9275C151.273 17.6362 152.33 19.178 153.955 19.8387L155.896 20.6276L152.664 22.6825C151.796 23.2348 151.273 24.1741 151.273 25.1811V40.3395C151.273 41.9366 152.608 43.2313 154.255 43.2313H168.769V22.5072H172V19.0372ZM159.077 22.5072H164.743V39.7612H155.299V24.917L159.077 22.5072Z"
      :fill="color"
    />
    <path
      d="M18.8876 36.717H15.944V25.2583H2.94352V36.717H0V11.5919H2.94352V22.6126H15.944V11.5919H18.8876V36.717Z"
      :fill="color"
    />
    <path
      d="M35.7819 37.1375C32.2193 37.1375 29.3634 35.9636 27.2142 33.6158C25.0767 31.268 24.0079 28.2135 24.0079 24.4523C24.0079 20.4108 25.1 17.187 27.2843 14.7808C29.4686 12.3746 32.4413 11.1714 36.2024 11.1714C39.6716 11.1714 42.4632 12.3395 44.5774 14.6756C46.7033 17.0118 47.7662 20.0662 47.7662 23.8391C47.7662 27.939 46.6799 31.1803 44.5074 33.5632C42.3348 35.946 39.4263 37.1375 35.7819 37.1375ZM35.9922 13.8346C33.3524 13.8346 31.209 14.7866 29.562 16.6905C27.915 18.5945 27.0916 21.0941 27.0916 24.1895C27.0916 27.2849 27.8917 29.7787 29.4919 31.6709C31.1038 33.5515 33.2005 34.4918 35.7819 34.4918C38.5386 34.4918 40.7112 33.5924 42.2997 31.7936C43.8883 29.9948 44.6826 27.4776 44.6826 24.2421C44.6826 20.9248 43.9116 18.3609 42.3698 16.5504C40.828 14.7399 38.7021 13.8346 35.9922 13.8346Z"
      :fill="color"
    />
    <path
      d="M63.0137 37.1375C59.4511 37.1375 56.5952 35.9636 54.4459 33.6158C52.3084 31.268 51.2396 28.2135 51.2396 24.4523C51.2396 20.4108 52.3317 17.187 54.516 14.7808C56.7003 12.3746 59.673 11.1714 63.4342 11.1714C66.9033 11.1714 69.695 12.3395 71.8092 14.6756C73.935 17.0118 74.998 20.0662 74.998 23.8391C74.998 27.939 73.9117 31.1803 71.7391 33.5632C69.5665 35.946 66.658 37.1375 63.0137 37.1375ZM63.2239 13.8346C60.5841 13.8346 58.4407 14.7866 56.7937 16.6905C55.1468 18.5945 54.3233 21.0941 54.3233 24.1895C54.3233 27.2849 55.1234 29.7787 56.7236 31.6709C58.3356 33.5515 60.4322 34.4918 63.0137 34.4918C65.7703 34.4918 67.9429 33.5924 69.5314 31.7936C71.12 29.9948 71.9143 27.4776 71.9143 24.2421C71.9143 20.9248 71.1434 18.3609 69.6015 16.5504C68.0597 14.7399 65.9338 13.8346 63.2239 13.8346Z"
      :fill="color"
    />
    <path
      d="M80.1183 36.717V11.5919H87.0566C95.9105 11.5919 100.337 15.6743 100.337 23.8391C100.337 27.717 99.1051 30.8358 96.6405 33.1953C94.1876 35.5431 90.8995 36.717 86.7762 36.717H80.1183ZM83.0618 14.2551V34.0538H86.8113C90.1052 34.0538 92.6691 33.1719 94.503 31.4081C96.3368 29.6443 97.2538 27.1447 97.2538 23.9092C97.2538 17.4731 93.8313 14.2551 86.9865 14.2551H83.0618Z"
      :fill="color"
    />
    <path
      d="M118.704 14.2551H111.45V36.717H108.506V14.2551H101.27V11.5919H118.704V14.2551Z"
      :fill="color"
    />
    <path
      d="M139.873 11.5919L131.586 27.4309V36.717H128.642V27.5009L120.565 11.5919H123.911L129.536 22.9455C129.606 23.0857 129.81 23.5996 130.149 24.4874H130.201C130.318 24.0902 130.54 23.5763 130.867 22.9455L136.754 11.5919H139.873Z"
      :fill="color"
    />
  </svg> -->
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: String,
      default: '130px',
    },
  },
};
</script>

<style lang="scss" scoped></style>
