import errorHandler from '../../../utils/errorHandler';
import { authorizedAxios } from '../../../config/axios';

export default {
  async fetchCompanyServices({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get('/api/v1/settings/services', {
        params,
      });

      commit('setCompanyServices', resp.data.data.services);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async addService({ commit, dispatch }, params) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.post(
        '/api/v1/settings/services',
        params
      );

      commit('setShowSuccessfulActionModal', true);
      dispatch('fetchCompanyServices');
      return resp;
    } catch (error) {
      errorHandler(error, 'serviceEdit');
    } finally {
      commit('setLoading', false);
    }
  },
  async updateService({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.post(
        `/api/v1/settings/services/${payload.id}`,
        payload.data
      );

      dispatch('fetchCompanyServices');
      commit('setShowSuccessfulActionModal', true);
      return resp;
    } catch (error) {
      errorHandler(error, 'serviceEdit');
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteService({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.delete(
        `/api/v1/settings/services/${payload.id}`
      );

      dispatch('fetchCompanyServices');
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteServiceImage({ commit }, params) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.post(
        `/api/v1/settings/services/${params.service}/remove-image`,
        { img_name: params.image }
      );

      commit('setShowSuccessfulActionModal', true);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCompanyLocations({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get('/api/v1/settings/locations', {
        params,
      });

      commit('setCompanyLocations', resp.data.data.locations);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCompanyCategories({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        '/api/v1/get-own-business-categories-and-subcategories',
        { params }
      );

      commit('setCompanyCategories', resp.data.data.categories);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCompanyServiceCategories({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get(
        '/api/v1/get-own-service-categories',
        { params }
      );

      commit('setCompanyServiceCategories', resp.data.data.categories);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchCompanyTypeDocs({ commit }, params = {}) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.get('/api/v1/get-company-type-docs', {
        params,
      });

      commit('setCompanyTypeDocs', resp.data.data[0]);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
