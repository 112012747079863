import storage from '../../../../config/localStorage';
import errorHandler from '../../../../utils/errorHandler';
import { authorizedAxios } from '../../../../config/axios';

export default {
  async fetchUserInfo({ commit, dispatch }) {
    try {
      commit('setLoading', true);
      const resp = await authorizedAxios.get(`/api/v1/auth-user`);
      commit('setUserInfo', resp.data.data.user);
      if (resp.data.data.user.role === 'user') {
        dispatch('fetchCart');
      }
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async logoutUser({ commit }) {
    try {
      commit('setLoading', true);
      const resp = await authorizedAxios.post(`/api/v1/logout`);
      storage.remove('access_token');
      commit('setLoggedIn', false);
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async uploadAvatar({ state, commit }, payload) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.post(
        '/api/v1/settings/upload-avatar',
        payload
      );

      commit('setUserInfo', {
        ...state.userInfo,
        ...resp.data.data.user,
      });
      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async updateUserInfo({ commit, getters }, payload) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.post('/api/v1/settings/info', payload);

      commit('setUserInfo', {
        ...resp.data.data.user[0],
        role: getters.userInfo.role,
      });
      commit('setToastAlert', {
        text: resp.data.message,
      });

      return resp;
    } catch (error) {
      errorHandler(error, 'updateUserInfo');
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteAccount({ commit }, id) {
    try {
      commit('setLoading', true);

      const resp = await authorizedAxios.delete(`/api/v1/settings/${id}`);

      storage.remove('access_token');
      commit('setLoggedIn', false);

      return resp;
    } catch (error) {
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
