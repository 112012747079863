import {
  getNotifications,
  markAllNotificationsRead,
  markNotificationRead,
} from '../../../services/notifications';
import errorHandler from '../../../utils/errorHandler';

export default {
  async fetchNotifications({ commit, getters }, params = {}) {
    try {
      commit('setLoading', true);
      const { data } = await getNotifications(params);
      console.log('data :>> ', data);

      if (!params.page || params.page === 1) {
        commit('setNotifications', data.data.notifications);
      } else {
        commit('addNotificationsPage', data.data.notifications);
      }
      commit('setUnreadNotifications', data.data.unread);
      commit('setNotificationsPagination', {
        totalItems: data.data.totalItems,
        pageSize: data.data.pageSize,
        currentPage: data.data.currentPage,
        pages: data.data.pages,
      });

      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async markAllNotificationsRead({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await markAllNotificationsRead(payload);
      console.log('data :>> ', data);

      commit('setUnreadNotifications', data.data.unread);
      commit('setNotifications', data.data.notifications);
      commit('setNotificationsPagination', {
        totalItems: data.data.totalItems,
        pageSize: data.data.pageSize,
        currentPage: data.data.currentPage,
        pages: data.data.pages,
      });

      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
  async markNotificationRead({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await markNotificationRead(payload);
      console.log('data :>> ', data);

      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      errorHandler(error);
    }
  },
};
