export default {
  userInfo: {},
  companyStaff: [],
  staffUsers: [],
  companyAppointments: [],
  adminRegistrationData: {
    type: '',
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    mobileNumber: '',
    country: '',
    city: '',
    address: '',
    zipcode: '',
    category: '',
    subcategory: '',
    taxId: '',
    companyType: null,
    personalausweis: null,
    gewerbeanmeldung: null,
    angaben_der_wirtschaftlich_berechtigten: null,
    gesellschaftervertrag: null,
    personalausweis_des_geschäftsführers_und_aller_wirtschaftlich_berechtigten_personen: null,
    aktueller_firmenbuchauszug_handelsregisterauszug: null,
    handwerkskarte: null,
  },
  adminUpdateInfoData: {
    company_name: '',
    phone_number: '',
    country: '',
    city: '',
    address: '',
    zipcode: '',
    category: '',
    sub_category: '',
  },
  userVerifyPhone: {
    callingCode: '',
    phoneNumber: '',
  },
};
