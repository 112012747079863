import {
  intervalToDuration,
  differenceInMinutes,
  format,
  startOfYear,
  endOfYear,
  sub,
  isWithinInterval,
  isBefore,
  isEqual,
  isPast,
  setHours,
  setMinutes,
  setSeconds,
  addMinutes,
  setMilliseconds,
} from 'date-fns';
import { intlRelativeTimeFormat } from '../config/intl';

// in miliseconds
const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

export const timeDuration = (from, to = Date.now()) => {
  const elapsed = to - from;

  for (const u in units)
    if (Math.abs(elapsed) > units[u] || u == 'second')
      return intlRelativeTimeFormat.format(-Math.round(elapsed / units[u]), u);
};

export const dateFormat = (date, formatString = 'y-MM-dd') => {
  return format(new Date(date), formatString);
};

export const getFullYearParams = date => {
  return {
    from: format(startOfYear(new Date(date)), 'y-MM-dd'),
    to: format(endOfYear(new Date(date)), 'y-MM-dd'),
  };
};

export const subtractTime = (date, time) => sub(new Date(date), time);

export const getTimeSlot = (startTime, endTime) => {
  return `${startTime.split(':')[0]}:${startTime.split(':')[1]} - ${
    endTime.split(':')[0]
  }:${endTime.split(':')[1]}`;
};

export const parseTime = t => {
  if (!t) {
    return t;
  }

  let d = new Date();
  let time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
  d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
  d.setMinutes(parseInt(time[2]) || 0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
};

export const getTimeSlots = (from, to, duration, conds = []) => {
  const step = x => addMinutes(x, duration);

  const blocks = [];
  let cursor = from;

  while (isBefore(step(cursor), to) || isEqual(step(cursor), to)) {
    if (isPast(cursor)) {
      cursor = step(cursor);
      continue;
    }

    let startH = ('0' + cursor.getHours()).slice(-2);
    let startM = ('0' + cursor.getMinutes()).slice(-2);
    let endH = ('0' + step(cursor).getHours()).slice(-2);
    let endM = ('0' + step(cursor).getMinutes()).slice(-2);

    if (!conds.length) {
      blocks.push({
        slot: `${startH}:${startM} - ${endH}:${endM}`,
        booked: false,
        placesLeft: 0,
      });
    } else {
      for (let index = 0; index < conds.length; index++) {
        const cond = conds[index];
        if (
          cond.from &&
          cond.to &&
          isWithinInterval(cursor, {
            start: cond.from,
            end: cond.to,
          }) &&
          isWithinInterval(step(cursor), {
            start: cond.from,
            end: cond.to,
          })
        ) {
          blocks.push({
            slot: `${startH}:${startM} - ${endH}:${endM}`,
            booked: false,
            placesLeft: 0,
          });
          break;
        }
      }
    }

    cursor = step(cursor);
  }

  return blocks;
};
