export default {
  setProducts: (state, payload) => {
    state.products = payload;
  },
  setProductCategories: (state, payload) => {
    state.productCategories = payload;
  },
  setCustomProductCategories: (state, payload) => {
    state.customProductCategories = payload;
  },
  setProductCompanies: (state, payload) => {
    state.productCompanies = payload;
  },
  setCompanyWithProducts: (state, payload) => {
    state.companyWithProducts = payload;
  },
};
