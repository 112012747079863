import { ref, watchEffect } from '@vue/composition-api';

export default function useSingleSelect(isOpen, emit) {
  const selected = ref(null);

  const selectSingleItem = selectedItem => {
    selected.value = selectedItem;
    isOpen.value = false;
    emit('input', selectedItem.value);
  };

  watchEffect(() => {
    // console.log('selected.value :>> ', selected.value);
  });

  return [selected, selectSingleItem];
}
