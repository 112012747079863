export default {
  categories: state => state.categories,
  subcategories: state => state.subcategories,
  categoriesOptions: state =>
    state.categories.map(item => ({
      label: item.name,
      value: item.id,
    })),
  subcategoriesOptions: state =>
    state.subcategories.map(item => ({
      label: item.name,
      value: item.id,
    })),
};
