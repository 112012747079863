export const caseConverter = string => {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const capitalize = string =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

export const convertHeadersToKeys = string =>
  string.toLowerCase().replace(/ /g, '_');

export const fullName = userObj => `${userObj?.firstName} ${userObj?.lastName}`;

export const convertKeysToHeaders = string => {
  let capitalized = string.charAt(0).toUpperCase() + string.slice(1);

  if (capitalized.includes('_')) {
    capitalized = capitalized.replace(/_/g, ' ');
  } else {
    capitalized = capitalized
      .split('')
      .map((char, index) => {
        if (index !== 0 && char === char.toUpperCase()) {
          return ` ${char.toLowerCase()}`;
        }
        return char;
      })
      .join('');
  }
  return capitalized;
};
