import { mainAxios, authorizedAxios } from '../../config/axios';

export const staffUserLogin = data =>
  mainAxios.post('/api/v1/staff/login', data);

export const forgotPasswordStaffUser = data =>
  mainAxios.post('/api/v1/staff/forgot-password', data);

export const resetPasswordStaffUser = data =>
  mainAxios.post('/api/v1/staff/reset-password', data);

export const getAllStaffUsers = params =>
  authorizedAxios.get('/api/v1/staff/users', { params });

export const getStaffUser = id =>
  authorizedAxios.get(`/api/v1/staff/users/${id}`);

export const addStaffUser = data =>
  authorizedAxios.post(`/api/v1/staff/users`, data);

export const updateStaffUser = (usedId, data) =>
  authorizedAxios.put(`/api/v1/staff/users/${usedId}`, data);

export const deleteStaffUser = userId =>
  authorizedAxios.delete(`/api/v1/staff/users/${userId}`);
