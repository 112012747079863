<template>
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5L3.92929 7.92929C3.96834 7.96834 4.03166 7.96834 4.07071 7.92929L11 1"
      stroke="white"
      stroke-width="1.7"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
