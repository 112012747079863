<template>
  <div class="banner-container">
    <slot />
  </div>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped>
.banner-container {
  border-radius: $hty-radius;
  background-color: $hty-grey;
  padding: 0.5rem 1rem;
  color: $hty-orange;
  @include hty-ellipsis;
}
</style>
