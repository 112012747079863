export default {
  setAppointments: (state, payload) => {
    state.appointments = payload;
  },
  setAppointmentsLoaded: (state, payload) => {
    state.appointmentsLoaded = payload;
  },
  setAppointmentById: (state, payload) => {
    state.appointmentById = payload;
  },
  setSelectedStaff: (state, payload) => {
    state.selectedStaff = payload;
  },
  setSelectedLocation: (state, payload) => {
    state.selectedLocation = payload;
  },
};
