<template>
  <div>
    <span
      v-for="value of orderData(data)"
      :key="value.key"
      class="notification-message"
      :class="styleValue(value.config)"
    >
      <span v-if="value.config.type === 'text'">
        {{ value.value }}
      </span>
      <span v-else-if="value.config.type === 'timerange'">
        ({{ value.value.start.slice(0, 16) }} -
        {{ value.value.end.slice(0, 5) }})
      </span>
      <span v-else-if="value.config.type === 'link'">
        <ButtonElement link :anchor="getLinkRoute(value.config.href)">
          {{ value.value }}
        </ButtonElement>
      </span>
      <span v-else-if="value.config.type === 'currency'">
        {{ intlCurrencyFormat(value.config.currency, 2).format(value.value) }}
      </span>
    </span>
  </div>
</template>

<script>
import { intlCurrencyFormat } from '../../config/intl';
import ButtonElement from '../FormControl/ButtonElement.vue';

export default {
  setup(props) {
    const orderData = obj => {
      return Object.entries(obj).map(([key, value], index) => {
        if (Number(key) === index) {
          return { ...value, key };
        }
      });
    };

    const styleValue = config => {
      return {
        bold: config.style === 'bold',
        message: props.type === 'message',
      };
    };

    return {
      styleValue,
      orderData,
      intlCurrencyFormat,
    };
  },
  methods: {
    getLinkRoute(link) {
      if (!link) {
        return {};
      }

      const url = new URL(link);
      return {
        name: 'Calendar',
        params: { view: 'grid', period: 'day' },
        query: {
          selectedDate: url.searchParams.get('selectedDate'),
          appointmentId: url.searchParams.get('appointment_id'),
          companyId: url.searchParams.get('company_id'),
          t: new Date().getTime(),
        },
      };
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'title',
    },
  },
  components: {
    ButtonElement,
  },
};
</script>

<style lang="scss" scoped>
.notification-message {
  &.bold {
    font-weight: bold;
    display: inline-block;
  }
  &.message {
    color: $hty-dark-grey;
    &:nth-child(2n) {
      margin-right: 20px;
    }
  }

  .anchor.button {
    display: inline-block;
    min-width: 83px;
  }
}
</style>
