export default {
  showAppointmentCreateModal: state => state.showAppointmentCreateModal,
  showAppointmentEditModal: state => state.showAppointmentEditModal,
  showAppointmentDetailsModal: state => state.showAppointmentDetailsModal,
  showAppointmentCancelModal: state => state.showAppointmentCancelModal,
  showAppointmentDeleteModal: state => state.showAppointmentDeleteModal,
  showSuccessfulActionModal: state => state.showSuccessfulActionModal,
  toastAlert: state => state.toastAlert,
  errorResponse: state => state.errorResponse,
};
