import defaultQueryParams from './defaultQueryParams';
import { dateFormat } from '@/utils/dateTimeHelpers';

export const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/admin/register',
    name: 'AdminRegister',
    components: {
      loginView: () =>
        import(/* webpackChunkName: "adminRegister" */ '../views/Register.vue'),
    },
    meta: { guest: true },
    props: {
      loginView: () => ({
        role: 'admin',
      }),
    },
  },
  {
    path: '/user/register',
    name: 'UserRegister',
    components: {
      loginView: () =>
        import(
          /* webpackChunkName: "userRegister" */ '../views/User/Register/UserRegister.vue'
        ),
    },
    meta: { guest: true },
    props: { loginView: true },
  },
  {
    path: '/user/verify-account',
    name: 'UserVerifyAccount',
    components: {
      loginView: () =>
        import(
          /* webpackChunkName: "userVerifyAccount" */ '../views/User/VerifyAccount/VerifyAccount.vue'
        ),
    },
    meta: { auth: false },
    props: true,
  },
  {
    path: '/login/:role',
    name: 'Login',
    components: {
      loginView: () =>
        import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    },
    meta: { guest: true },
    props: { loginView: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    components: {
      loginView: () =>
        import(
          /* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue'
        ),
    },
    meta: { guest: true },
    props: {
      loginView: route => ({
        role: route.query.role,
      }),
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    components: {
      loginView: () =>
        import(
          /* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'
        ),
    },
    meta: { guest: true },
    props: {
      loginView: route => ({
        role: route.query.role,
        token: route.query.token,
        email: route.query.email,
      }),
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '../views/Notifications.vue'
      ),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: {
      name: 'Calendar',
      params: { view: 'grid', period: 'day' },
      query: {
        selectedDate: dateFormat(Date.now(), 'y-MM-dd'),
        sortBy: defaultQueryParams.Calendar.sortBy,
        sort: defaultQueryParams.Calendar.sort,
      },
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/calendar',
    redirect: { name: 'Calendar', params: { view: 'grid', period: 'day' } },
  },
  {
    path: '/calendar/:view/:period',
    name: 'Calendar',
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ '../views/Calendar/Calendar.vue'
      ),
    meta: { auth: true },
    props: route => ({
      view: route.params.view,
      period: route.params.period,
      selectedDate:
        route.query.selectedDate || defaultQueryParams.Calendar.selectedDate,
      sortBy: route.query.sortBy || defaultQueryParams.Calendar.sortBy,
      sort: route.query.sort || defaultQueryParams.Calendar.sort,
    }),
  },
  {
    path: '/social-projects',
    component: () => import('../views/SocialProjects/Container.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'SocialProjects',
        component: () => import('../views/SocialProjects/List.vue'),
        meta: { auth: true },
      },
      {
        path: ':id',
        name: 'SocialProjectsDetails',
        component: () => import('../views/SocialProjects/Details.vue'),
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Contact.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () =>
      import(
        /* webpackChunkName: "settingsCompany" */ '../views/Settings/Settings.vue'
      ),
    meta: { auth: true },
    children: [
      {
        path: 'company',
        name: 'SettingsCompany',
        component: () =>
          import(
            /* webpackChunkName: "settingsCompany" */ '../views/Settings/Company/Company.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'products',
        name: 'SettingsProducts',
        component: () =>
          import(
            /* webpackChunkName: "settingsProducts" */ '../views/Admin/Settings/SettingsProducts.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'products/create',
        name: 'SettingsProductsCreate',
        component: () =>
          import(
            /* webpackChunkName: "settingsProductsCreate" */ '../views/Admin/Settings/SettingsProductsCreate.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'terminen',
        name: 'SettingsTerminen',
        component: () =>
          import(
            /* webpackChunkName: "settingsProducts" */ '../views/Settings/Products/Products.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'profile',
        name: 'SettingsProfile',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '../views/Settings/User/Profile.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'services',
        name: 'SettingsServices',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '../views/Admin/Settings/SettingsServices.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'services/create',
        name: 'SettingsServicesCreate',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '../views/Admin/Settings/SettingsServicesCreate.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'staff',
        name: 'SettingsStaff',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '../views/Admin/Settings/SettingsStaff.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: '*',
        redirect: { name: 'SettingsCompany' },
      },
    ],
  },
  {
    path: '/services',
    component: () =>
      import(
        /* webpackChunkName: "services" */ '../views/Services/Services.vue'
      ),
    meta: { auth: true },
    props: { type: 'service_provider' },
    children: [
      {
        path: 'categories',
        name: 'ServiceCategories',
        component: () =>
          import(
            /* webpackChunkName: "serviceCategories" */ '../views/Services/Categories/CategoriesList.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'category/:id',
        name: 'ServiceCategoryDetails',
        component: () =>
          import(
            /* webpackChunkName: "serviceCategoryDetails" */ '../views/Services/Categories/CategoryDetails.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'book-appointment/:id',
        name: 'ServiceBookAppointment',
        component: () =>
          import(
            /* webpackChunkName: "serviceBookAppointment" */ '../views/Services/BookAppointment/BookAppointment.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: '/',
        name: 'Services',
        redirect: { name: 'ServiceCategories' },
      },
      {
        path: '*',
        redirect: { name: 'ServiceCategories' },
      },
    ],
  },
  {
    path: '/retailer',
    component: () =>
      import(
        /* webpackChunkName: "services" */ '../views/Services/Services.vue'
      ),
    meta: { auth: true },
    props: { type: 'retailer' },
    children: [
      {
        path: 'categories',
        name: 'RetailerCategories',
        component: () =>
          import(
            /* webpackChunkName: "serviceCategories" */ '../views/Services/Categories/CategoriesList.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'category/:id',
        name: 'RetailerCategoryDetails',
        component: () =>
          import(
            /* webpackChunkName: "serviceCategoryDetails" */ '../views/Services/Categories/CategoryDetails.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'book-appointment/:id',
        name: 'RetailerBookAppointment',
        component: () =>
          import(
            /* webpackChunkName: "serviceBookAppointment" */ '../views/Services/BookAppointment/BookAppointment.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: '*',
        redirect: { name: 'RetailerCategories' },
      },
    ],
  },
  {
    path: '/products',
    component: () =>
      import(
        /* webpackChunkName: "products" */ '../views/Products/Products.vue'
      ),
    meta: { auth: true },
    props: { type: 'retailer' },
    children: [
      {
        path: 'categories',
        name: 'ProductsCategories',
        component: () =>
          import(
            /* webpackChunkName: "productsCategories" */ '../views/Products/ProductsCategories.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'categories/:category',
        name: 'ProductsCategoriesCategory',
        component: () =>
          import(
            /* webpackChunkName: "productsCategoriesCategory" */ '../views/Products/ProductsCategoriesCategory.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'company/:company/product/:product',
        name: 'ProductsProduct',
        component: () =>
          import(
            /* webpackChunkName: "productsProduct" */ '../views/Products/ProductsProduct.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: 'company/:id',
        name: 'ProductsCompany',
        component: () =>
          import(
            /* webpackChunkName: "productsCompany" */ '../views/Products/ProductsCompany.vue'
          ),
        meta: { auth: true },
        props: true,
      },
      {
        path: '/',
        name: 'Products',
        redirect: { name: 'ProductsCategories' },
      },
    ],
  },
  {
    path: '/home',
    name: 'Home',
    components: {
      loginView: () =>
        import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    meta: { guest: true },
    props: { loginView: true },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Cart.vue'),
    meta: { auth: true },
  },
  {
    path: '/callback',
    name: 'Callback',
    components: {
      loginView: () =>
        import(/* webpackChunkName: "callback" */ '../views/Callback.vue'),
    },
    meta: { guest: true },
    props: {
      loginView: route => ({
        token: route.query.token,
      }),
    },
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () =>
      import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue'),
    meta: { auth: true },
  },
  {
    path: '*',
    name: 'NotFound',
    components: {
      notFoundView: () =>
        import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    },
  },
];
