<template>
  <div
    v-if="shouldShow"
    :class="'alert ' + (response.success ? 'alert-success' : 'alert-danger')"
  >
    <template v-if="response.data && response.data.errors">
      <ul v-for="(value, key) in response.data.errors" :key="key">
        <template v-if="Array.isArray(value)">
          <li v-for="(err, index) in value" :key="index">
            {{ err }}
          </li>
        </template>
      </ul>
    </template>
    <template v-else-if="!response.exception && !response.message">
      <ul v-for="(value, key) in response" :key="key">
        <template v-if="Array.isArray(value)">
          <li v-for="(err, index) in value" :key="index">
            {{ err }}
          </li>
        </template>
      </ul>
    </template>
    <template v-else-if="response.message">
      <p class="mb-0">{{ message }}</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    response: {
      type: Object,
      required: false,
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    hideSuccess: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.response = this.response || this.$store.getters.errorResponse.data;
  },
  computed: {
    isEmpty() {
      return !this.response || Object.keys(this.response).length === 0;
    },

    shouldShow() {
      if (this.isEmpty) {
        return false;
      }

      if (this.response.success && this.hideSuccess) {
        return false;
      }

      if (!this.response.success && this.hideErrors) {
        return false;
      }

      return true;
    },

    message() {
      if (this.response.message) {
        if (this.response.message == 'The given data was invalid.') {
          return 'Die angegebenen Daten sind ungültig.';
        }

        return this.response.message;
      }

      return this.response.success ? 'Success.' : 'Error.';
    },
  },
};
</script>

<style lang="scss">
.alert {
  ul {
    padding-inline-start: 20px;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
