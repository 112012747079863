<template>
  <label :for="id" class="radio-container d-flex align-items-center">
    <input
      :id="id"
      class="radio"
      type="radio"
      :value="radioValue"
      :name="name"
      :disabled="disabled"
      v-model="radioButtonValue"
    />
    <div class="radio-custom" :class="{ active: radioValue === value }">
      <div
        class="radio-custom-secondary"
        :class="{ active: radioValue === value }"
      ></div>
    </div>
    <span v-if="text" class="radio-text pl-2" :style="textStyle">
      {{ text }}
    </span>
  </label>
</template>

<script>
import { computed, ref, watchEffect } from '@vue/composition-api';

export default {
  setup(props, { emit }) {
    const handleChange = event => {
      emit('input', event.target.value);
    };

    const radioButtonValue = computed({
      get: () => props.value,
      set: () => emit('input', props.radioValue),
    });

    const id = computed(() => `id_${props.value}_${Math.random() * 100}`);

    return {
      handleChange,
      id,
      radioButtonValue,
    };
  },
  props: {
    radioValue: {
      type: [Number, String, Boolean],
      required: true,
    },
    value: {
      type: [Number, String, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    textStyle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-container {
  position: relative;
}
.radio {
  position: absolute;
  display: none;
}
.radio-text {
  color: #737373;
  font-weight: 500;
  cursor: pointer;
}
.radio-custom {
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  background-color: transparent;
  position: relative;
  border: 1px solid #737373;

  &.active {
    border-color: $hty-orange;
  }
}
.radio-custom-secondary {
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background-color: white;

  &.active {
    border: 1px solid $hty-orange;
    width: 10px;
    height: 10px;
    background-color: $hty-orange;
  }
}
</style>
