import Vue from 'vue';
import Vuex from 'vuex';
import general from './general';
import users from './users';
import categories from './categories';
import notifications from './notifications';
import modal from './modal';
import appointments from './appointments';
import settings from './settings';
import services from './services';
import products from './products';
import cart from './cart';

Vue.use(Vuex);

export const store = {
  modules: {
    general,
    users,
    categories,
    notifications,
    modal,
    appointments,
    settings,
    services,
    products,
    cart,
  },
  strict: process.env.NODE_ENV !== 'production',
};

export default new Vuex.Store(store);
