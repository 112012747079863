import errorHandler from '../../../../utils/errorHandler';
import {
  endUserLogin,
  endUserVerifyPhoneConfirmCode,
  endUserVerifyPhoneSendCode,
  forgotPasswordEndUser,
  registerEndUser,
  resetPasswordEndUser,
} from '../../../../services/users/user';
import storage from '../../../../config/localStorage';

export default {
  async loginEndUser({ commit }, { email, password, validator }) {
    try {
      commit('setLoading', true);
      const { data } = await endUserLogin({ email, password });
      storage.set('access_token', data.data.authToken);
      commit('setLoggedIn', true);
    } catch (error) {
      if (error?.response?.data.message) {
        validator.setErrors({
          email: [error.response.data.message],
          password: [error.response.data.message],
        });
      }
      errorHandler(error, 'login');
    } finally {
      commit('setLoading', false);
    }
  },
  async registerEndUser({ commit }, payload) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);
      await registerEndUser(payload);
    } catch (error) {
      errorHandler(error, 'registerEndUser');
    } finally {
      commit('setLoading', false);
    }
  },
  async forgotPasswordEndUser({ commit }, payload) {
    try {
      commit('setLoading', true);
      const { data } = await forgotPasswordEndUser(payload);
      commit('setToastAlert', { text: data.message });
    } catch (error) {
      errorHandler(error, 'forgotPassword');
    } finally {
      commit('setLoading', false);
    }
  },
  async resetPasswordEndUser({ commit }, payload) {
    try {
      commit('setLoading', true);
      await resetPasswordEndUser(payload);
    } catch (error) {
      errorHandler(error, 'resetPassword');
    } finally {
      commit('setLoading', false);
    }
  },
  async verifyPhoneSendCode({ commit }, payload) {
    try {
      commit('setErrorResponse', {});
      commit('setLoading', true);
      await endUserVerifyPhoneSendCode(payload);
    } catch (error) {
      errorHandler(error, 'verifyPhoneSendCode');
    } finally {
      commit('setLoading', false);
    }
  },
  async verifyPhoneConfirmCode({ commit }, { payload, validator }) {
    try {
      commit('setLoading', true);
      await endUserVerifyPhoneConfirmCode(payload);
    } catch (error) {
      if (error?.response?.data.message) {
        validator.setErrors({
          code: [error.response.data.message],
        });
      }
      errorHandler(error);
    } finally {
      commit('setLoading', false);
    }
  },
};
