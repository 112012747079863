export default {
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  setNotFound({ commit }, payload) {
    commit('setNotFound', payload);
  },
  setOfflineBanner({ commit }, payload) {
    commit('setOfflineBanner', true);

    setTimeout(() => {
      commit('setOfflineBanner', false);
    }, 4000);
  },
};
