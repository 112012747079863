import storage from '../../localStorage';
import router from '../../../router';
import store from '../../../store';

export const redirectionInterceptorResponseError = err => {
  // console.log('err.response, err.config :>> ', err.response, err.config);

  if (
    err.response?.status === 403 &&
    (err.response?.data.message.includes('Phone verification needed') ||
      err.response?.data.message.includes('Telefonverifizierung erforderlich')) //SM: this needs major refactoring...
  ) {
    router.push({ name: 'UserVerifyAccount' });
  }
};
