<template>
  <section class="notification-icon-wrapper">
    <div class="icon">
      <Component
        :is="handleIconSelection.icon"
        :color="handleIconSelection.color"
      />
    </div>
  </section>
</template>

<script>
import { computed, watchEffect } from '@vue/composition-api';
import SlackIcon from '../Svgs/SlackIcon.vue';
import SettingsNotificationIcon from '../Svgs/NotificationIcons/SettingsNotificationIcon.vue';
import CalendarNotificationIcon from '../Svgs/NotificationIcons/CalendarNotificationIcon.vue';

export default {
  setup(props) {
    const handleIconSelection = computed(() => {
      const mapper = {
        AppointmentMade: { icon: CalendarNotificationIcon, color: undefined },
        AppointmentCanceled: {
          icon: CalendarNotificationIcon,
          color: '#E51313',
        },
        Settings: { icon: SettingsNotificationIcon, color: undefined },
      };

      return mapper[props.type] || { icon: SlackIcon, color: undefined };
    });

    watchEffect(() => {
      console.log('props.type :>> ', props.type);
    });

    return {
      handleIconSelection,
    };
  },
  components: {
    CalendarNotificationIcon,
    SettingsNotificationIcon,
    SlackIcon,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  background: #fdfdfd;
  box-shadow: $hty-shadow;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  border-left: 4px solid white;

  &.active {
    border-left: 4px solid $hty-orange;
  }

  > :first-child {
    margin-right: 1rem;
  }
}
.notification-content {
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
.time {
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2;
  color: $hty-grey-text;
}
</style>
