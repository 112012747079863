import { getTimeSlots } from '../../utils/dateTimeHelpers';

export default {
  serviceType: state => state.serviceType,
  serviceCategories: state => state.serviceCategories,
  serviceCompanies: state => state.serviceCompanies,
  serviceCompanyDetails: state => state.serviceCompanyDetails,
  serviceSelectedService: state => state.serviceSelectedService,
  serviceCompanyServices: state =>
    state.serviceCompanyDetails ? state.serviceCompanyDetails.services : [],
  serviceCompanyWorkingHours: state =>
    state.serviceCompanyDetails && state.serviceCompanyDetails.workingHours
      ? JSON.parse(state.serviceCompanyDetails.workingHours)
      : [],
  serviceStaffWorkingHours: () => (staff, dayInWeek) => {
    const days = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
    let selectedDay =
      typeof dayInWeek === 'number' ? days[dayInWeek - 1] : dayInWeek;
    return JSON.parse(staff.workingHours)[selectedDay];
  },
  serviceCompanyWorkingHoursByDay: (state, getters) => dayInWeek => {
    const days = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
    let selectedDay =
      typeof dayInWeek === 'number' ? days[dayInWeek - 1] : dayInWeek;
    return getters.serviceCompanyWorkingHours[selectedDay];
  },
  serviceCompanyIsWorking: (state, getters) => dayInWeek => {
    const workingHoursForDay = getters.serviceCompanyWorkingHoursByDay(
      dayInWeek
    );
    return (
      workingHoursForDay &&
      workingHoursForDay.hours.from &&
      workingHoursForDay.hours.to
    );
  },
  serviceCompanyTimeSlots: (state, getters) => (
    dayInWeek,
    selectedDay,
    selectedStaff
  ) => {
    const workingHoursForDay = getters.serviceCompanyWorkingHoursByDay(
      dayInWeek
    );
    const staffWorkingHours = getters.serviceStaffWorkingHours(
      selectedStaff,
      dayInWeek
    );
    const createDate = t => (t ? new Date(`${selectedDay} ${t}`) : null);

    // Generate time slots based on staff working hours and company working hours as condition
    let timeSlots = [];
    const serviceDuration = parseInt(getters.serviceSelectedService.duration);
    const startTime = createDate(workingHoursForDay.hours.from);
    const breakStartTime = createDate(workingHoursForDay.break.from);
    const breakEndTime = createDate(workingHoursForDay.break.to);
    const endTime = createDate(workingHoursForDay.hours.to);
    const staffStartTime = createDate(staffWorkingHours.hours.from);
    const staffEndTime = createDate(staffWorkingHours.hours.to);
    const staffBreakStartTime = createDate(staffWorkingHours.break.from);
    const staffBreakEndTime = createDate(staffWorkingHours.break.to);

    let conds = [];

    if (breakStartTime && breakEndTime) {
      conds = [
        {
          from: startTime,
          to: breakStartTime,
        },
        {
          from: breakEndTime,
          to: endTime,
        },
      ];
    } else {
      conds = [
        {
          from: startTime,
          to: endTime,
        },
      ];
    }

    if (staffBreakStartTime && staffBreakEndTime) {
      const timeSlotsBeforeBreak = getTimeSlots(
        staffStartTime,
        staffBreakStartTime,
        serviceDuration,
        conds
      );
      const timeSlotsAfterBreak = getTimeSlots(
        staffBreakEndTime,
        staffEndTime,
        serviceDuration,
        conds
      );

      timeSlots = [...timeSlotsBeforeBreak, ...timeSlotsAfterBreak];
    } else {
      timeSlots = getTimeSlots(
        staffStartTime,
        staffEndTime,
        serviceDuration,
        conds
      );
    }

    // Check if slot is already booked
    timeSlots.forEach(i => {
      const times = i.slot.split(' - ');
      i.booked = getters.isAppointmentAlreadyBookedForStaff(
        selectedDay,
        times[0] + ':00',
        times[1] + ':00',
        selectedStaff.staffId
      );
    });

    return timeSlots;
  },
  serviceCompaniesTable: state =>
    state.serviceCompanies
      ? state.serviceCompanies
          .map(i => ({
            logo: i.image ? i.image : 'https://via.placeholder.com/83',
            company: i.company,
            // popular_services: i.name,
            // rating: 5,
            // price_rating: 5,
            price_from: i.priceMin,
            distance: i.distance,
          }))
          .sort((a, b) => a.distance - b.distance)
      : [],
  serviceBookAppointment: state => state.serviceBookAppointmentPayload,
};
