import { mainAxios, authorizedAxios } from '../../config/axios';

export const registerEndUser = data =>
  mainAxios.post('/api/v1/user/register', data);

export const endUserLogin = data => mainAxios.post('/api/v1/user/login', data);

export const forgotPasswordEndUser = data =>
  mainAxios.post('/api/v1/user/forgot-password', data);

export const resetPasswordEndUser = data =>
  mainAxios.post('/api/v1/user/reset-password', data);

export const endUserVerifyPhoneSendCode = data =>
  authorizedAxios.post('/api/v1/user/verify-phone-send-code', data);

export const endUserVerifyPhoneConfirmCode = data =>
  authorizedAxios.post('/api/v1/user/verify-phone', data);
